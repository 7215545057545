import { DadosCalculoAntecipacao } from '@store/impostoRenda/antecipacao/types'

import { BaseServices, HttpMethod } from './base'

export default (data: PostCalculoAntecipacao, utmSource: string, cpf: string) => {
  const pathname = process.env.REACT_APP_POST_CALCULO_ANTECIPACAO
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST
  const headers = {
    utmSource,
    cpfCnpj: cpf,
  }

  const response = BaseServices.request<DadosCalculoAntecipacao>({
    headers,
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface PostCalculoAntecipacao {
  valorDisponivelRestituicao: number
  score: number
}
