import { TermosDados } from '@store/impostoRenda/assinatura/types'

import { BaseServices, HttpMethod } from './base'

export default (simulacao: number, cpfCnpj: string) => {
  const headers = {
    cpfCnpj,
  }

  const pathname = `${process.env.REACT_APP_FINALIZAR_PROPOSTA}?simulacao=${simulacao}`
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<TermosDados>({
    headers,
    pathname,
    method,
    host,
  })
  return response
}
