import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { GridStyled, Paragraph, Separator } from '@atoms'
import { Page } from '@templates'
import { Colors } from '@utils/Colors'
import { formatCurrencySemCifrao } from '@utils/functions'
import { Button } from '@interco/inter-ui/components/Button'
import routes from '@routes/routes'
import { ApplicationState } from '@store/types'
import { postCallFinalizarProposta } from '@store/impostoRenda/assinatura/actions'
import GoogleTag from '@utils/GoogleTag'
import { CreditIcon } from '@icons/Signals'
import Help from '@interco/icons/orangeds/SM/help'

import { BottomSheetCet } from './Bottomsheets/BottomSheetCet'
import { Detalhes } from './Detalhes'
import { Span } from './styles'
import { BottomSheetValor } from './Bottomsheets/BottomSheetValor'

const RevisaoAntecipacao = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showBottomSheetCet, setShowBottomSheetCet] = useState(false)
  const [showBottomSheetValor, setShowBottomSheetValor] = useState(false)

  const { loading } = useSelector((state: ApplicationState) => state.impostoRenda.assinatura)
  const { cpf } = useSelector((state: ApplicationState) => state.impostoRenda.dadosConta)
  const { dadosCalculoAntecipacao, valorRestituicao } = useSelector(
    (state: ApplicationState) => state.impostoRenda.antecipacao,
  )

  return (
    <Page
      footer={
        <Button
          fullWidth
          onClick={() => {
            GoogleTag.impostoRendaTag({
              ref_figma: 4,
              content_id: 'Continuar para a revisão',
              content_action: 'clique',
              screen: 'Resultado da Antecipação',
              total_disponivel: valorRestituicao,
              valor_antecipado: dadosCalculoAntecipacao.valorBruto,
              currency: 'BRL',
            })
            dispatch(
              postCallFinalizarProposta({
                simulacao: dadosCalculoAntecipacao?.idSimulacao,
                cpfCnpj: process.env.REACT_APP_MOCK_CPF || cpf || '',
                callback: () => {
                  navigate(routes.VISUALIZAR_DOCUMENTO)
                },
              }),
            )
          }}
          isLoading={loading}
        >
          Continuar
        </Button>
      }
    >
      <GridStyled justify="space-around" margin="40px 0 0">
        <CreditIcon />
      </GridStyled>
      <Paragraph textAlign="center" margin="24px 0 8px 0">
        Valor a receber
        <Span>
          <Help
            color={Colors.PRIMARY500}
            width={16}
            height={16}
            onClick={() => setShowBottomSheetValor(true)}
          />
        </Span>
      </Paragraph>
      <Paragraph
        textAlign="center"
        fontSize="24px"
        lineHeight="30px"
        fontFamily="sora"
        color={Colors.SUCCESS500}
        fontWeight={600}
      >
        R$ {formatCurrencySemCifrao(dadosCalculoAntecipacao.valorLiquido)}
      </Paragraph>
      <Separator variant="small" margin="24px 0" />
      <Detalhes
        parcelamento="Parcela única"
        dataDebito={dadosCalculoAntecipacao.dtVencimento}
        taxaJuros={dadosCalculoAntecipacao.txJurosEfetivaMensal}
        taxaCET={dadosCalculoAntecipacao.txCetAnual}
        valorAntecipacao={dadosCalculoAntecipacao.valorLiquido}
        valorDebitado={dadosCalculoAntecipacao.valorBruto}
        iof={dadosCalculoAntecipacao.valorIof}
        setShowBottomSheetCet={setShowBottomSheetCet}
      />

      {showBottomSheetCet ? (
        <BottomSheetCet setShowBottomSheetCet={setShowBottomSheetCet} />
      ) : (
        <></>
      )}
      {showBottomSheetValor ? (
        <BottomSheetValor setShowBottomSheetValor={setShowBottomSheetValor} />
      ) : (
        <></>
      )}
    </Page>
  )
}

export default RevisaoAntecipacao
