/**
 * Actions Types
 */
export enum ValidaTypes {
  VALIDA_SET_LOADING = '@impostoRenda/valida/VALIDA_SET_LOADING',
  POST_ISAFE_AUTORIZACAO = '@impostoRenda/valida/POST_ISAFE_AUTORIZACAO',
  RESET = 'RESET',
}

export interface ValidaState {
  readonly loading: boolean
}

export interface PayloadAutorizacao {
  callback: () => void
  erro: () => void
  erroAutorizacao: () => void
  token: string
  tipoAutenticacao: string
  valor: string
  conta: string
}
