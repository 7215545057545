import { BridgeService } from '@services/bridge'

interface TagProps {
  ref_figma: number
  content_id: string
  screen: string
  content_action?: string
  area?: string
  total_disponivel?: string | number
  valor_antecipado?: string | number
  currency?: string
  flow?: string
  client?: boolean
  product?: string
}

export default {
  impostoRendaTag: ({
    ref_figma,
    content_id,
    screen,
    content_action,
    area,
    total_disponivel,
    valor_antecipado,
    currency,
    flow,
    client,
    product,
  }: TagProps) => {
    if (!BridgeService.isBrowser()) {
      BridgeService.requestAnalytics('ANTECIPACAO_IR', {
        ref_figma: String(ref_figma),
        content_id: String(content_id),
        screen: String(screen),
        content_action: String(content_action),
        area: String(area),
        total_disponivel: String(total_disponivel),
        valor_antecipado: String(valor_antecipado),
        currency: String(currency),
        flow: String(flow),
        client: String(client),
        product: String(product),
      })
    }
  },
}
