import styled from 'styled-components'

import { Colors } from '@utils/Colors'

export const HeaderLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const OrangeButton = styled.button`
  font-family: Inter;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: ${Colors.PRIMARY500};
`
export const ContainerSlide = styled.div`
  display: flex;
  flex-direction: column;
`

export const FooterLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
`
export const LineDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 88px;
`
export const Line = styled.div<{ actived: boolean }>`
  width: 24px;
  height: 2px;
  background-color: ${({ actived }) => (actived ? Colors.PRIMARY500 : Colors.GRAY200)}; ;
`
