export const bottomsheetVariants = {
  valorInsuficiente: {
    title: 'Valor insuficiente para antecipação',
    description: 'O valor mínimo que pode ser antecipado é de ',
    additionalValue: true,
  },
  valorMaximo: {
    title: 'Valor não permitido para antecipação',
    description: 'O valor máximo que pode ser antecipado é de ',
    additionalValue: true,
  },
  contaInvalida: {
    title: 'Instituição financeira inválida',
    description:
      'Identificamos que a instituição financeira selecionada para recebimento da restituição não é o Inter, por esse motivo não foi possível prosseguir com sua solicitação.',
  },
  erroAntecipacao: {
    title: 'Não foi possível solicitar sua antecipação',
    description:
      'Estamos trabalhando para resolver o problema o mais breve possível. Tenta novamente mais tarde.',
  },
  declaracaoNaoFinalizada: {
    title: 'Declaração não finalizada',
    description:
      'Identificamos que a sua solicitação de Declaração de Imposto de Renda não foi finalizada. Para prosseguir, precisaremos que a declaração esteja concluída - Em Fila de Restituição.',
  },
  nenhumaAutorizacaoEncontrada: {
    title: 'Não foi possível simular sua antecipação',
    description:
      'Para fazer a simulação é necessário autorizar o compartilhamento dos seus dados no site do e-CAC.',
  },
}
