import React from 'react'

import { Paragraph } from '@atoms'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Colors } from '@utils/Colors'
import { Button } from '@interco/inter-ui/components/Button'

interface Props {
  setShowBottomSheet: React.Dispatch<React.SetStateAction<boolean>>
  variant: {
    title: string
    text: string
    button: string
  }
}
export const BottomSheetError = ({ setShowBottomSheet, variant }: Props) => (
  <BottomSheet onClose={() => setShowBottomSheet(false)}>
    <Paragraph
      fontFamily="Sora"
      fontSize="16px"
      lineHeight="20px"
      color={Colors.GRAY500}
      fontWeight={600}
      margin="24px 0 8px 0"
    >
      {variant.title}
    </Paragraph>

    <Paragraph margin="8px 0 24px 0">{variant.text}</Paragraph>
    <Button fullWidth onClick={() => setShowBottomSheet(false)}>
      {variant.button}
    </Button>
  </BottomSheet>
)
