import React, { useState } from 'react'

import { useSelector } from 'react-redux'

import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import SliderTutorial from '@organisms/SliderTutorial'
import { ApplicationState } from '@store/types'

import { PassoUm } from './PassoUm'
import { PassoDois } from './PassoDois'
import { PassoTres } from './PassoTres'
import { PassoQuatro } from './PassoQuatro'
import { PassoCinco } from './PassoCinco'
import { bottomsheetVariants } from '../Bottomsheet/variants'
import Bottomsheet from '../Bottomsheet'

interface Props {
  setOpen(isOpen: boolean): void
}

interface Variant {
  title: string
  description: string
  additionalValue?: boolean
}

const TutorialAutorizacao = ({ setOpen }: Props) => {
  const [showBottomsheet, setShowBottomsheet] = useState(false)
  const [variant, setVariant] = useState<Variant>(bottomsheetVariants.erroAntecipacao)
  const { valorAdicional } = useSelector(
    (state: ApplicationState) => state.impostoRenda.antecipacao,
  )

  return (
    <>
      <BottomSheet onClose={() => setOpen(false)}>
        <SliderTutorial
          setOpen={setOpen}
          templatesArray={[
            <PassoUm key="passo1" />,
            <PassoDois key="passo2" />,
            <PassoTres key="passo3" />,
            <PassoQuatro key="passo4" />,
            <PassoCinco
              key="passo5"
              setVariant={setVariant}
              setShowBottomsheet={setShowBottomsheet}
            />,
          ]}
        />
      </BottomSheet>

      {showBottomsheet ? (
        <Bottomsheet
          onClose={() => setShowBottomsheet(false)}
          title={variant.title}
          description={variant.description}
          additionalValue={variant?.additionalValue}
          value={valorAdicional}
        />
      ) : (
        <></>
      )}
    </>
  )
}
export default TutorialAutorizacao
