import { Paragraph } from '@atoms'
import { Colors } from '@utils/Colors'

export const perguntasFrequentes = [
  {
    pergunta: 'Como funciona a antecipação de restituição do Imposto de Renda?',
    resposta: (
      <Paragraph fontSize="12px" lineHeight="15px" margin="0">
        A Antecipação de Restituição de Imposto de Renda é uma forma segura de você receber mais
        rapidamente um dinheiro que é, de seu direito, restituído pelo governo meses após a coleta
        do Imposto de Renda.
      </Paragraph>
    ),
  },
  {
    pergunta: 'Como adiantar a restituição do meu Imposto de renda?',
    resposta: (
      <>
        <Paragraph fontSize="12px" lineHeight="15px">
          Depois de declarar seu Imposto de Renda, você entrega sua documentação no site da Receita
          Federal e, nesse momento, seleciona o Inter como instituição financeira na qual deseja
          receber sua restituição.
        </Paragraph>
        <Paragraph fontSize="12px" lineHeight="15px" margin="0">
          Em seguida, basta acessar o Portal e-CAC pra liberar a nossa consulta aos seus dados por
          12 meses. Feito isso, acesse a área de Empréstimos aqui do Super App Inter, realize uma
          simulação gratuita e concorde com os termos e condições do contrato.
        </Paragraph>
      </>
    ),
  },
  {
    pergunta: 'Quais as vantagens de antecipar minha restituição?',
    resposta: (
      <ul>
        <li>
          <Paragraph fontSize="12px" lineHeight="15px" margin="0">
            Contratação 100% digital e simplificada
          </Paragraph>
        </li>
        <li>
          <Paragraph fontSize="12px" lineHeight="15px" margin="0">
            Não precisa apresentar nenhuma documentação
          </Paragraph>
        </li>
        <li>
          <Paragraph fontSize="12px" lineHeight="15px" margin="0">
            Recebimento do valor em parcela única
          </Paragraph>
        </li>
        <li>
          <Paragraph fontSize="12px" lineHeight="15px" margin="0">
            Dinheiro rápido na sua conta
          </Paragraph>
        </li>
        <li>
          <Paragraph fontSize="12px" lineHeight="15px" margin="0">
            Taxas fixas e competitivas
          </Paragraph>
        </li>
      </ul>
    ),
  },
  {
    pergunta: 'Como o crédito é realizado?',
    resposta: (
      <Paragraph fontSize="12px" lineHeight="15px" margin="0">
        Depois de você concluir a contratação da sua Antecipação de Restituição de Imposto de Renda,
        fazemos uma análise simplificada das suas informações, e o valor adiantado cai em até 4 dias
        úteis na sua conta Inter.
      </Paragraph>
    ),
  },
  {
    pergunta: 'Quais são as condições de contratação?',
    resposta: (
      <Paragraph fontSize="12px" lineHeight="15px" margin="0">
        Pra contratar a Antecipação de Restituição de Imposto de Renda, você só precisa ter a partir
        de R$ 300,00 disponíveis pra receber após realizar a sua declaração do ano vigente.
      </Paragraph>
    ),
  },
]

export const PERGUNTA_PADDING = 'Quais as vantagens de antecipar minha restituição?'

export const PropostasStatus = {
  EM_ANALISE: 'EM_ANALISE',
  APROVADO: 'APROVADO',
  PAGO: 'PAGO',
  CANCELADO: 'CANCELADO',
  LIQUIDADO: 'LIQUIDADO',
  REPROVADO: 'REPROVADO',
  AGUARDANDO_PAGAMENTO: 'AGUARDANDO_PAGAMENTO',
}

export const tagOption = {
  AGUARDANDO_PAGAMENTO: {
    bgColor: Colors.RASPBERRY100,
    nameColor: Colors.RASPBERRY500,
    nameDescription: 'Aguardando pagamento',
  },
  EM_ANALISE: {
    bgColor: Colors.RASPBERRY100,
    nameColor: Colors.RASPBERRY500,
    nameDescription: 'Em análise',
  },
  APROVADO: {
    bgColor: Colors.SUCCESS100,
    nameColor: Colors.SUCCESS500,
    nameDescription: 'Aprovado',
  },
  CANCELADO: {
    bgColor: Colors.ERROR100,
    nameColor: Colors.ERROR500,
    nameDescription: 'Cancelado',
  },
  PAGO: {
    bgColor: Colors.SUCCESS100,
    nameColor: Colors.SUCCESS500,
    nameDescription: 'Pago',
  },
  LIQUIDADO: {
    bgColor: Colors.SUCCESS100,
    nameColor: Colors.SUCCESS500,
    nameDescription: 'Liquidado',
  },
  REPROVADO: {
    bgColor: Colors.ERROR100,
    nameColor: Colors.ERROR500,
    nameDescription: 'Cancelado',
  },
}
