import { BaseServices, HttpMethod } from '@services/base'
import { PropostasEmAndamentoResponse } from '@store/impostoRenda/antecipacao/types'

export default async () => {
  const pathname = `${process.env.REACT_APP_CONSULTA_PROPOSTAS_ANDAMENTO}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<PropostasEmAndamentoResponse>({
    pathname,
    method,
    host,
  })

  return response
}
