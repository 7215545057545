import React, { useEffect } from 'react'

import FeedbackScreen from '@organisms/FeedbackScreen'
import GoogleTag from '@utils/GoogleTag'

const Sucesso = () => {
  useEffect(() => {
    GoogleTag.impostoRendaTag({
      ref_figma: 7,
      content_id: 'Antecipação realizada com sucesso!',
      content_action: 'tela carregada',
      screen: 'Sucesso',
    })
  }, [])
  return (
    <FeedbackScreen
      feedbackType="sucesso"
      title="Antecipação realizada com sucesso!"
      description="Recebemos sua solicitação, em breve retornaremos via e-mail."
      onClickButton={() => {
        GoogleTag.impostoRendaTag({
          ref_figma: 6,
          content_id: 'Ir para a home',
          content_action: 'clique',
          screen: 'Sucesso',
        })
        window.location.href = process.env.REACT_APP_HOME_EMPRESTIMO || ''
      }}
    />
  )
}
export default Sucesso
