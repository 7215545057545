export const PropostaVariant = {
  propostaAndamento: {
    title: 'Solicitação em andamento',
    descriptionFirstParagraph:
      'Você já solicitou a Antecipação da Restituição de Imposto de Renda.Aguarde que, em breve, você receberá uma notificação sobre o resultado.',
    buttonText: 'Acompanhar solicitação',
  },
  propostaRealizada: {
    title: 'Solicitação de antecipação já realizada',
    descriptionFirstParagraph:
      'Só é possível realizar uma solicitação de Antecipação de Imposto de Renda por ano.',
    descriptionSecondParagraph:
      'Confira mais detalhes sobre seu contrato em andamento na nossa Área do Cliente.',
    buttonText: 'Entendi',
  },
}
