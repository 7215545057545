import { AnyAction } from 'redux'
import { call, put, select } from 'typed-redux-saga'

import { showError } from '@store/ui/error/actions'
import getExisteProposta from '@services/getExisteProposta'
import { ApplicationState } from '@store/types'
import { setCpf } from '@store/impostoRenda/dadosConta/actions'
import { HttpStatus } from '@services/base'

import { PayloadPropostasAndamento } from '../types'
import { callGetExisteProposta, setExisteProposta, setLoading, setStatusProposta } from '../actions'

interface ErrorType {
  data: {
    tipoErro: string
    detalhes: string
  }
  status: HttpStatus
}
export default function* getExistePropostaSaga(action: AnyAction) {
  const payload = action?.payload as PayloadPropostasAndamento

  try {
    const { cpf } = yield* select((s: ApplicationState) => s.impostoRenda.dadosConta)
    yield put(setLoading(true))
    const { data } = yield* call(getExisteProposta, cpf)
    yield put(setLoading(false))
    yield put(setExisteProposta(data.existePropostaAndamento))
    yield put(setStatusProposta(data.status))
    if (data.cpf) {
      yield put(setCpf(data.cpf))
    }
    payload.callback(data.existePropostaAndamento, data.status)
  } catch (error) {
    yield put(setLoading(false))
    const errorResponse = error as ErrorType
    if (errorResponse?.status === HttpStatus.PRECONDITION_FAILED) {
      yield put(
        showError({
          title: 'Ocorreu um erro',
          message: 'Tipo da conta não permitido para contratação',
          block: true,
        }),
      )
    } else {
      yield put(
        showError({
          title: 'Erro ao consultar dados da proposta',
          message: 'Ocorreu um erro ao consultar dados da proposta',
          actionTitle: 'Tentar Novamente',
          actionCallback: () => callGetExisteProposta(payload),
          block: true,
        }),
      )
    }
  }
}
