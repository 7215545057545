import { SagaGenerator, takeLatest } from 'typed-redux-saga'

import {
  getConsultaRestituicaoSaga,
  postCalculoAntecipacaoSaga,
  getPropostasEmAndamentoSaga,
  getExistePropostaSaga,
} from './antecipacao/sagas'
import getConsultaAntifraudeSaga from './antecipacao/sagas/getConsultaAntifraudeSaga'
import { AntecipacaoTypes } from './antecipacao/types'
import { postAssinarPropostaSaga, postFinalizarPropostaSaga } from './assinatura/sagas'
import { AssinaturaTypes } from './assinatura/types'
import { postAutorizacaoISafeSaga } from './valida/sagas'
import { ValidaTypes } from './valida/types'

export default function* watch(): SagaGenerator<void> {
  yield* takeLatest(ValidaTypes.POST_ISAFE_AUTORIZACAO, postAutorizacaoISafeSaga)
  yield* takeLatest(AntecipacaoTypes.GET_VALOR_RESTITUICAO, getConsultaRestituicaoSaga)
  yield* takeLatest(AntecipacaoTypes.POST_CALCULO_ANTECIPACAO, postCalculoAntecipacaoSaga)
  yield* takeLatest(AssinaturaTypes.POST_ASSINAR_PROPOSTA, postAssinarPropostaSaga)
  yield* takeLatest(AssinaturaTypes.POST_FINALIZAR_PROPOSTA, postFinalizarPropostaSaga)
  yield* takeLatest(AntecipacaoTypes.GET_PROPOSTAS_ANDAMENTO, getPropostasEmAndamentoSaga)
  yield* takeLatest(AntecipacaoTypes.GET_CONSULTA_ANTIFRAUDE, getConsultaAntifraudeSaga)
  yield* takeLatest(AntecipacaoTypes.GET_EXISTE_PROPOSTA, getExistePropostaSaga)
}
