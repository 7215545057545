import React from 'react'

import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { ErrorIcon } from '@icons/Signals'
import { GridStyled } from '@atoms'
import Paragraph from '@atoms/Paragraph/styles'
import { Colors } from '@utils/Colors'
import { Button } from '@interco/inter-ui/components/Button'
import { formatCurrency } from '@utils/functions'

interface Props {
  title: string
  description: string
  additionalValue?: boolean
  value?: number
  onClose: () => void
}
const Bottomsheet = ({ title, description, additionalValue, value, onClose }: Props) => (
  <BottomSheet onClose={onClose}>
    <GridStyled justify="space-around">
      <ErrorIcon />
    </GridStyled>
    <Paragraph
      fontFamily="Sora"
      fontSize="16px"
      lineHeight="20px"
      color={Colors.GRAY500}
      fontWeight={600}
      margin="24px 0 8px 0"
    >
      {title}
    </Paragraph>

    <Paragraph margin="8px 0 24px 0">
      {additionalValue ? `${description}${formatCurrency(value)}` : description}
    </Paragraph>

    <Button fullWidth onClick={() => onClose()}>
      Entendi
    </Button>
  </BottomSheet>
)

export default Bottomsheet
