import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import qs from 'qs'
import { useLocation } from 'react-router-dom'

import { Page } from '@templates'
import { TabPager, TabDefinition } from '@interco/inter-ui/components/TabPager'
import { interWbSession } from '@interco/inter-webview-bridge'
import { BridgeService } from '@services/bridge'
import {
  setConta,
  setCpf,
  setDataNascimento,
  setEmail,
  setNome,
  setSegmentation,
  setTelefone,
} from '@store/impostoRenda/dadosConta/actions'
import { getUtmSourceFromParams, timestampToDatePt } from '@utils/functions'
import useMobileUserInfo from '@hooks/useMobileUserInfo'
import GoogleTag from '@utils/GoogleTag'
import { ApplicationState } from '@store/types'
import { setCurrentTab } from '@store/ui/navigation/actions'
import { reset } from '@store/impostoRenda/actions'
import { setUtmSource } from '@store/session/actions'

import HomeSimulador from './Simulador'
import AreaCliente from './AreaCliente'

const Home = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const userInfo = useMobileUserInfo()
  const { enableTab, currentTab } = useSelector((state: ApplicationState) => state.ui.navigation)

  useEffect(() => {
    const params = qs.parse(location?.search, { ignoreQueryPrefix: true })
    const utmSource = getUtmSourceFromParams(params)
    dispatch(setUtmSource(utmSource))
  }, [dispatch, location?.search])

  useEffect(() => {
    dispatch(reset())
    dispatch(setCurrentTab('Simulador'))
  }, [dispatch])

  useEffect(() => {
    if (!BridgeService.isBrowser()) {
      interWbSession.editToolbar('Antecipação de IR', true)
    }
  }, [])

  // GET USER INFO
  useEffect(() => {
    dispatch(setTelefone(userInfo?.cellPhoneNumber || ''))
    dispatch(setEmail(userInfo?.email || ''))
    dispatch(setCpf(userInfo?.cpf || ''))
    dispatch(setNome(userInfo?.name || ''))
    dispatch(setConta(userInfo?.account || ''))
    dispatch(setDataNascimento(timestampToDatePt(Number(userInfo?.birthDate)) || ''))
    dispatch(setSegmentation(userInfo?.segmentation || ''))
  }, [userInfo, dispatch])

  const tabDefinitions: TabDefinition[] = [
    {
      tabId: 'Simulador',
      label: 'Simulador',
      content: <HomeSimulador />,
    },
    {
      tabId: 'Area do Cliente',
      label: 'Área do Cliente',
      disabled: !enableTab,
      content: <AreaCliente />,
    },
  ]

  const handleTabChange = (tabId: string | number) => {
    GoogleTag.impostoRendaTag({
      ref_figma: 1,
      content_id: String(tabId),
      screen: 'Home',
      content_action: 'clique',
    })
    dispatch(setCurrentTab(String(tabId)))
  }
  return (
    <Page>
      <TabPager
        currentTabId={currentTab}
        onTabSelect={handleTabChange}
        tabDefinitions={tabDefinitions}
        tabPanelStyle={{
          padding: '24px 0 0 0 ',
        }}
        tabListStyle={{
          justifyContent: 'space-between',
        }}
      />
    </Page>
  )
}
export default Home
