import React from 'react'

export default () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="64" height="64" rx="32" fill="var(--primary100)" />
    <path
      d="M30.0001 32H33.3334C34.8062 32 36.0001 33.1939 36.0001 34.6667V34.6667C36.0001 36.1394 34.8062 37.3333 33.3334 37.3333H26.6667"
      stroke="var(--primary500)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36 34.612L39.4003 33.0265C41.042 32.2609 42.9887 33.046 43.64 34.7363V34.7363C44.231 36.2704 43.5334 38.0001 42.0435 38.6949L34.2565 42.326C31.922 43.4146 29.3053 43.7447 26.7736 43.27L20 42"
      stroke="var(--primary500)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 34.6667C20 31.7212 22.3878 29.3334 25.3333 29.3334C27.3074 29.3334 29.031 30.4059 29.9532 32"
      stroke="var(--primary500)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.6666 21.3334H35.9999H34.6666C33.9302 21.3334 33.3333 21.9303 33.3333 22.6667V22.6667C33.3333 23.4031 33.9302 24 34.6666 24H35.9999H37.3332C38.0696 24 38.6666 24.597 38.6666 25.3334V25.3334C38.6666 26.0698 38.0696 26.6667 37.3333 26.6667H33.3333"
      stroke="var(--primary500)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36 21.3333L36 20"
      stroke="var(--primary500)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36 28L36 26.6666"
      stroke="var(--primary500)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
