/**
 * Actions Types
 */

export enum DadosContaTypes {
  SET_TELEFONE = '@impostoRenda/dadosConta/SET_TELEFONE',
  SET_EMAIL = '@impostoRenda/dadosConta/SET_EMAIL',
  SET_CHECK_TELEFONE = '@impostoRenda/dadosConta/SET_CHECK_TELEFONE',
  SET_CONTA = '@impostoRenda/dadosConta/SET_CONTA',
  SET_NOME = '@impostoRenda/dadosConta/SET_NOME',
  SET_CPF = '@impostoRenda/dadosConta/SET_CPF',
  SET_DATA_NASCIMENTO = '@impostoRenda/dadosConta/SET_DATA_NASCIMENTO',
  SET_SEGMENTATION = '@impostoRenda/dadosConta/SET_SEGMENTATION',
  RESET = 'RESET',
}

/**
 * State Type
 */

export interface DadosContaState {
  readonly telefone: string
  readonly email: string
  readonly checkTelefone: boolean
  readonly conta: string
  readonly nome: string
  readonly cpf: string
  readonly dataNascimento: string
  readonly segmentation: string
}
