import React, { useEffect, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Paragraph } from '@atoms'
import { ISafeBottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Button } from '@interco/inter-ui/components/Button'
import { Colors } from '@utils/Colors'
import { ApplicationState } from '@store/types'
import {
  postCallAssinarProposta,
  setCheckTermos,
  setEnableButton,
} from '@store/impostoRenda/assinatura/actions'
import { postIsafeAutorizacao } from '@store/impostoRenda/valida/actions'
import { variants } from '@pages/RevisaoAntecipacao/Variants'
import { IWbISafeResponse } from '@interco/inter-webview-bridge'
import routes from '@routes/routes'
import { BridgeService } from '@services/bridge'
import { isOldBridge } from '@services/base/oldBridge'
import { CheckboxCustom, LinkTermoAutorizacao } from '@pages/RevisaoAntecipacao/styles'
import { useSnackBar, SnackBarProvider } from '@interco/inter-ui/components/SnackBar'
import Attention from '@interco/icons/orangeds/SM/attention'
import { BottomSheetError } from '@pages/RevisaoAntecipacao/Bottomsheets/BottomSheetError'

import { Div, FooterGrid, PageVisualizarPdf, PdfGrid } from './styles'
import CCB from './CCB'

const TermoCCB = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const ref = useRef<HTMLDivElement>(null)
  const [scrollDocument, setScrollDocument] = useState(false)
  const [showISafe, setShowISafe] = useState(false)
  const [showBottomSheet, setShowBottomSheet] = useState(false)
  const [variant, setVariant] = useState(variants.autenticacaoRealizada)
  let isafeResponse: IWbISafeResponse | undefined
  const { conta } = useSelector((state: ApplicationState) => state.impostoRenda.dadosConta)

  const { checkTermos, enableButton, loading } = useSelector(
    (state: ApplicationState) => state.impostoRenda.assinatura,
  )
  const { termoDados } = useSelector((state: ApplicationState) => state.impostoRenda.assinatura)
  const { dadosCalculoAntecipacao } = useSelector(
    (state: ApplicationState) => state.impostoRenda.antecipacao,
  )
  const { showSnackBar } = useSnackBar()

  useEffect(() => {
    showSnackBar({
      title: 'Atenção',
      content: 'Para assinar é necessário ler o contrato até o final.',
      type: 'NULL',
      hasProgressBar: true,
      customIcon: <Attention height={20} width={20} color={Colors.WHITE} />,
    })
  }, [showSnackBar])

  useEffect(() => {
    dispatch(setEnableButton(scrollDocument && checkTermos))
  }, [checkTermos, dispatch, scrollDocument])

  const onAuthenticateComplete = async (status: boolean) => {
    if (!isOldBridge() && !BridgeService.isBrowser()) {
      isafeResponse = await BridgeService?.authenticateRequestISafe(
        dadosCalculoAntecipacao.valorLiquido,
      )
    }
    if (status && isafeResponse) {
      const { authenticationToken, authenticationType, authenticationValue } = isafeResponse

      dispatch(
        postIsafeAutorizacao({
          callback: () => {
            setShowISafe(false)
            dispatch(
              postCallAssinarProposta({
                codigoProposta: termoDados.codigoProposta,
                callback: () => {
                  navigate(routes.SUCESSO)
                },
              }),
            )
          },
          erro: () => {
            setShowISafe(false)
            setVariant(variants.erroAutenticacao)
            setShowBottomSheet(true)
          },
          erroAutorizacao: () => {
            setShowISafe(false)
            setVariant(variants.erroAutorizacao)
            setShowBottomSheet(true)
          },
          tipoAutenticacao: `${authenticationType || ''}`,
          token: `${authenticationToken || ''}`,
          valor: `${authenticationValue || ''}`,
          conta: conta || '',
        }),
      )
    } else {
      setShowISafe(false)
      setVariant(variants.erroAutenticacao)
      setShowBottomSheet(true)
    }
  }

  const iSafeAuthenticate = async () => Promise.resolve()

  // const iSafeAuthenticate = async (): Promise<void> => {
  //   if (!isOldBridge() && !BridgeService.isBrowser()) {
  //     isafeResponse = await BridgeService?.authenticateRequestISafe(
  //       dadosCalculoAntecipacao.valorLiquido,
  //     )
  //   }
  //   return Promise.resolve()
  // }

  return (
    <PageVisualizarPdf>
      <PdfGrid ref={ref}>
        <CCB pdfGridRef={ref} setScrollDocument={setScrollDocument} />
      </PdfGrid>
      <FooterGrid>
        <CheckboxCustom
          id="checkbox-consulta-siape"
          reversed
          checked={checkTermos}
          onChange={(e) => dispatch(setCheckTermos(e.target.checked))}
          label={
            <Paragraph color={Colors.GRAY500} fontSize="12px">
              Li e concordo com os{' '}
              <LinkTermoAutorizacao>
                Termos e condições da Antecipação de Restituição de IR.
              </LinkTermoAutorizacao>
            </Paragraph>
          }
        />
        <Button
          fullWidth
          disabled={!enableButton || loading}
          onClick={() => setShowISafe(true)}
          isLoading={loading}
        >
          Antecipar agora
        </Button>
      </FooterGrid>
      {showISafe && (
        <ISafeBottomSheet
          authenticate={iSafeAuthenticate}
          onComplete={onAuthenticateComplete}
          onClose={() => setShowISafe(false)}
          description="Para sua segurança estamos autenticando sua transação com o i-safe."
        />
      )}
      {showBottomSheet && (
        <BottomSheetError setShowBottomSheet={setShowBottomSheet} variant={variant} />
      )}
    </PageVisualizarPdf>
  )
}

const VisualizarDocumento = () => (
  <Div>
    <SnackBarProvider>
      <TermoCCB />
    </SnackBarProvider>
  </Div>
)

export default VisualizarDocumento
