import { AnyAction } from 'redux'
import { call, put } from 'typed-redux-saga'

import postFinalizarProposta from '@services/postFinalizarProposta'
import { showError } from '@store/ui/error/actions'

import { PayloadFinalizarProposta } from '../types'
import { postCallFinalizarProposta, setError, setLoading, setTermoDados } from '../actions'

export default function* postFinalizarPropostaSaga(action: AnyAction) {
  const payload = action?.payload as PayloadFinalizarProposta
  try {
    yield put(setLoading(true))
    const { data } = yield* call(postFinalizarProposta, payload.simulacao, payload.cpfCnpj)
    yield put(setTermoDados(data))
    yield put(setLoading(false))
    payload.callback()
  } catch (error) {
    yield put(setLoading(false))
    yield put(setError())
    yield put(
      showError({
        title: 'Erro ao finalizar a proposta',
        message: 'Ocorreu um erro ao finalizar a proposta',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => postCallFinalizarProposta(payload),
        block: true,
      }),
    )
  }
}
