/**
 * Converte um number para string de moeda(Real) sem cifrão
 * @param value Number
 * @returns 999,99
 */
export const formatCurrencySemCifrao = (value: number | '' | undefined) =>
  !value
    ? '0,00'
    : value.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })

/**
 * Retorna o valor convertido de uma string para number
 * @param value String
 * @returns  999.99
 */
export const currencyToFloat = (value: string) => {
  if (value) {
    const numValue = Number(value.replace(/[^\d]+/g, '')) / 100
    return parseFloat(numValue.toFixed(2))
  }
  return 0
}

/**
 * Converte um number para string de moeda(Real)
 * @param value Number
 * @returns R$ 999,99
 */
export const formatCurrency = (value: number | '' | undefined) =>
  !value
    ? 'R$ 0,00'
    : value.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
      })

export const timestampToDatePt = (timestamp: number) => {
  if (!timestamp) return null
  const getDate =
    new Date(timestamp).getUTCDate() < 10
      ? `0${new Date(timestamp).getUTCDate()}`
      : new Date(timestamp).getUTCDate()
  const getMonth =
    new Date(timestamp).getMonth() + 1 < 10
      ? `0${new Date(timestamp).getMonth() + 1}`
      : new Date(timestamp).getMonth() + 1
  const getYear = new Date(timestamp).getFullYear()
  return `${getDate}/${getMonth}/${getYear}`
}

export const formatDate = (data: string) =>
  new Date(data)?.toLocaleDateString('pt-br', {
    timeZone: 'UTC',
  })

export const getUtmSourceFromParams = (params: qs.ParsedQs) => {
  if (params?.utm_source) return params.utm_source.toString()
  if (params?.utmSource) return params.utmSource.toString()
  return ''
}
