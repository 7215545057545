import styled from 'styled-components'

import { Paragraph } from '@atoms'
import { Colors } from '@utils/Colors'
import { Accordion } from '@interco/inter-ui/components/Accordion'
import { Tag } from '@interco/inter-ui/components/Tag'
import { Chips } from '@interco/inter-ui/components/Chips'
import { Button } from '@interco/inter-ui/components/Button'

export const ImgOnboarding = styled.img`
  @media (max-width: 500px) {
    width: 100vw;
  }
  width: 100%;
  margin: 0 -24px;
`
export const PFlexStart = styled(Paragraph)`
  align-self: flex-start;
`

export const WrapLoadingText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;
`
export const InfoLine = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 14px;
`

export const InfoText = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`

export const CustomLabelAccordion = styled.div`
  display: flex;
  flex-direction: row;
  background: none;
  justify-content: space-between;
`

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;

  width: 32px;
  height: 32px;

  background: ${Colors.PRIMARY100};
  border-radius: 32px;
`
export const AccordionCustom = styled(Accordion)<{ styleProp: boolean }>`
  background: none;
  padding: 16px 0;
  border-bottom: 1px solid ${Colors.GRAY200};
  & > div {
    &:nth-child(2) {
      & > div {
        padding: ${(props) => (props.styleProp ? '20px 16px' : '20px 0')};
      }
    }
  }
`
export const IconArea = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: ${Colors.PRIMARY100};
  border-radius: 8px;
`

export const TextArea = styled.div`
  width: 70%;
`
export const CustomTag = styled(Tag)`
  margin: 0;
`
export const FilterLine = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  margin: 24px 0 16px 0;
`
export const CustomChips = styled(Chips)`
  text-align: center;
  margin: 0 8px 0 0;
`
export const CustomButton = styled(Button)`
  margin-top: 24px;
`
