import { AnyAction } from 'redux'
import { call, put, select } from 'typed-redux-saga'

import postCalculoAntecipacao from '@services/postCalculoAntecipacao'
import { showError } from '@store/ui/error/actions'
import { ApplicationState } from '@store/types'

import { PayloadCalculoAntecipacao } from '../types'
import {
  calculoAntecipacaoError,
  calculoAntecipacaoSuccess,
  callPostCalculoAntecipacao,
  setLoading,
} from '../actions'

export default function* postCalculoAntecipacaoSaga(action: AnyAction) {
  const payload = action?.payload as PayloadCalculoAntecipacao
  try {
    const { session } = yield* select((s: ApplicationState) => s)
    const { cpf } = yield* select((s: ApplicationState) => s.impostoRenda.dadosConta)
    yield put(setLoading(true))
    const { data } = yield* call(
      postCalculoAntecipacao,
      {
        valorDisponivelRestituicao: payload.valorDisponivelRestituicao,
        score: payload.score,
      },
      session.utmSource || '',
      cpf,
    )
    yield put(calculoAntecipacaoSuccess(data))
    yield put(setLoading(false))
    yield payload.callback()
  } catch (error) {
    yield put(setLoading(false))
    yield put(calculoAntecipacaoError())
    yield put(
      showError({
        title: 'Erro ao calcular antecipação',
        message: 'Ocorreu um erro ao calcular a antecipação solicitada',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => callPostCalculoAntecipacao(payload),
        block: true,
      }),
    )
  }
}
