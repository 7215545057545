/**
 * Actions Types
 */

export enum AssinaturaTypes {
  SET_CHECK_TERMOS = '@impostoRenda/assinatura/SET_CHECK_TERMOS',
  SET_ENABLE_BUTTON = '@impostoRenda/assinatura/SET_ENABLE_BUTTON',
  SET_LOADING = '@impostoRenda/assinatura/SET_LOADING',
  SET_ERROR = '@impostoRenda/assinatura/SET_ERROR',
  SET_CODIGO_PROPOSTA = '@impostoRenda/assinatura/SET_CODIGO_PROPOSTA',
  POST_FINALIZAR_PROPOSTA = '@impostoRenda/assinatura/POST_FINALIZAR_PROPOSTA',
  SET_TERMO = '@impostoRenda/assinatura/SET_TERMO',
  POST_ASSINAR_PROPOSTA = '@impostoRenda/assinatura/POST_ASSINAR_PROPOSTA',

  SET_TERMO_DADOS = '@impostoRenda/assinatura/SET_TERMO_DADOS',
  RESET = 'RESET',
}

export interface TermosDados {
  codigoProposta: string
  agencia: string
  banco: string
  contaCorrente: string
  cpfConjuge: string
  dataEmissao: string
  documentoIdentificacao: string
  endereco: string
  estadoCivil: string
  jurosCapitalizado: boolean
  liberacaoCredito: string
  nacionalidade: string
  naturalidade: string
  nomeConjuge: string
  nomeMae: string
  nomePai: string
  previsaoPrimeiroDesconto: string
  previsaoUltimoDesconto: string
  qtdParcelasMensais: string
  seguro: false
  tarifaCadastro: string
  valorParcelaMensal: number
  pracaDePagamento: string
  valorGarantia: string
  valorSeguro: string
  termo: string
  valorDoMutuo: string
}

/**
 * State Type
 */

export interface AssinaturaState {
  readonly checkTermos: boolean
  readonly enableButton: boolean
  readonly loading: boolean
  readonly error: boolean
  readonly termo: string
  readonly termoDados: TermosDados
}

export interface PayloadFinalizarProposta {
  simulacao: number
  cpfCnpj: string
  callback: () => void
}

export interface PayloadAssinarProposta {
  codigoProposta: string
  callback: () => void
}
