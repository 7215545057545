import React from 'react'

import { Paragraph } from '@atoms'
import { Colors } from '@utils/Colors'
import { formatCurrency, formatCurrencySemCifrao } from '@utils/functions'
import Help from '@interco/icons/orangeds/SM/help'

import { LineDetailsDescription, Span } from '../styles'

interface Props {
  parcelamento: string
  dataDebito: string
  taxaJuros: number
  taxaCET: number
  valorAntecipacao: number
  valorDebitado: number
  setShowBottomSheetCet: (value: React.SetStateAction<boolean>) => void
  iof: number
}

export const Detalhes = ({
  parcelamento,
  dataDebito,
  taxaJuros,
  taxaCET,
  valorAntecipacao,
  valorDebitado,
  setShowBottomSheetCet,
  iof,
}: Props) => (
  <>
    <Paragraph
      textAlign="left"
      fontSize="16px"
      lineHeight="20px"
      fontFamily="sora"
      color={Colors.GRAY500}
      fontWeight={600}
      margin="0 0 16px 0"
    >
      Detalhamento de valores
    </Paragraph>
    <LineDetailsDescription>
      <Paragraph fontSize="12px" lineHeight="15px">
        Parcelamento
      </Paragraph>
      <Paragraph color={Colors.GRAY500} fontSize="12px" lineHeight="15px" fontWeight={700}>
        {parcelamento}
      </Paragraph>
    </LineDetailsDescription>
    <LineDetailsDescription>
      <Paragraph fontSize="12px" lineHeight="15px">
        Data da cobrança
      </Paragraph>
      <Paragraph color={Colors.GRAY500} fontSize="12px" lineHeight="15px" fontWeight={700}>
        {new Date(dataDebito)?.toLocaleDateString('pt-br', {
          timeZone: 'UTC',
        })}
      </Paragraph>
    </LineDetailsDescription>
    <LineDetailsDescription>
      <Paragraph fontSize="12px" lineHeight="15px">
        Taxa de juros
      </Paragraph>
      <Paragraph color={Colors.GRAY500} fontSize="12px" lineHeight="15px" fontWeight={700}>
        {formatCurrencySemCifrao(taxaJuros)}% a.m
      </Paragraph>
    </LineDetailsDescription>
    <LineDetailsDescription>
      <Paragraph fontSize="12px" lineHeight="15px">
        IOF
      </Paragraph>
      <Paragraph color={Colors.GRAY500} fontSize="12px" lineHeight="15px" fontWeight={700}>
        {formatCurrency(iof)}
      </Paragraph>
    </LineDetailsDescription>
    <LineDetailsDescription>
      <Paragraph fontSize="12px" lineHeight="15px" margin="0">
        Custo Efetivo Total (CET)
        <Span>
          <Help
            color={Colors.PRIMARY500}
            width={16}
            height={16}
            onClick={() => setShowBottomSheetCet(true)}
          />
        </Span>
      </Paragraph>
      <Paragraph color={Colors.GRAY500} fontSize="12px" lineHeight="15px" fontWeight={700}>
        {formatCurrencySemCifrao(taxaCET)}% a.a
      </Paragraph>
    </LineDetailsDescription>
    <LineDetailsDescription>
      <Paragraph fontSize="12px" lineHeight="15px">
        Valor a receber
      </Paragraph>
      <Paragraph color={Colors.GRAY500} fontSize="12px" lineHeight="15px" fontWeight={700}>
        {formatCurrency(valorAntecipacao)}
      </Paragraph>
    </LineDetailsDescription>
    <LineDetailsDescription>
      <Paragraph fontSize="12px" lineHeight="15px">
        Valor a pagar
      </Paragraph>
      <Paragraph color={Colors.GRAY500} fontSize="12px" lineHeight="15px" fontWeight={700}>
        {formatCurrency(valorDebitado)}
      </Paragraph>
    </LineDetailsDescription>
  </>
)
