import React from 'react'

import { Route, BrowserRouter, Routes } from 'react-router-dom'

import { GoBackConfig } from '@utils/goBackConfig'
import {
  Home,
  Validacao,
  Sucesso,
  RevisaoAntecipacao,
  VisualizarDocumento,
  AntecipacaoIndisponivel,
} from '@pages'

import routes from './routes'

const RoutesRefin: React.FC = () => (
  <BrowserRouter>
    <GoBackConfig />
    <Routes>
      <Route path={routes.HOME} element={<Home />} />
      <Route path={routes.VALIDACAO} element={<Validacao />} />
      <Route path={routes.SUCESSO} element={<Sucesso />} />
      <Route path={routes.REVISAO_ANTECIPACAO} element={<RevisaoAntecipacao />} />
      <Route path={routes.VISUALIZAR_DOCUMENTO} element={<VisualizarDocumento />} />
      <Route path={routes.ANTECIPACAO_INDISPONIVEL} element={<AntecipacaoIndisponivel />} />
    </Routes>
  </BrowserRouter>
)

export default RoutesRefin
