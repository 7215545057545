/**
 * Actions Types
 */

export enum AntecipacaoTypes {
  GET_VALOR_RESTITUICAO = '@impostoRenda/antecipacao/GET_VALOR_RESTITUICAO',
  SET_VALOR_RESTITUICAO = '@impostoRenda/antecipacao/SET_VALOR_RESTITUICAO',
  SET_LOADING = '@impostoRenda/antecipacao/SET_LOADING',

  POST_CALCULO_ANTECIPACAO = '@impostoRenda/antecipacao/POST_CALCULO_ANTECIPACAO',
  CALCULO_ANTECIPACAO_SUCESS = '@impostoRenda/antecipacao/CALCULO_ANTECIPACAO_SUCESS',
  CALCULO_ANTECIPACAO_ERROR = '@impostoRenda/antecipacao/CALCULO_ANTECIPACAO_ERROR',

  GET_PROPOSTAS_ANDAMENTO = '@impostoRenda/antecipacao/GET_PROPOSTAS_ANDAMENTO',
  PROPOSTAS_ANDAMENTO_SUCESS = '@impostoRenda/antecipacao/PROPOSTAS_ANDAMENTO_SUCESS',
  PROPOSTAS_ANDAMENTO_ERROR = '@impostoRenda/antecipacao/PROPOSTAS_ANDAMENTO_ERROR',

  GET_CONSULTA_ANTIFRAUDE = '@impostoRenda/antecipacao/GET_CONSULTA_ANTIFRAUDE',
  SET_SCORE = '@impostoRenda/antecipacao/SET_SCORE',
  SET_PRESENCA_LISTA_ANTIFRAUDE = '@impostoRenda/antecipacao/SET_PRESENCA_LISTA_ANTIFRAUDE',
  SET_DECISAO = '@impostoRenda/antecipacao/SET_DECISAO',

  SET_EXISTE_PROPOSTA = '@impostoRenda/antecipacao/SET_EXISTE_PROPOSTA',
  SET_STATUS_PROPOSTA = '@impostoRenda/antecipacao/SET_STATUS_PROPOSTA',
  GET_EXISTE_PROPOSTA = '@impostoRenda/antecipacao/GET_EXISTE_PROPOSTA',

  SET_VALOR_ADICIONAL = '@impostoRenda/antecipacao/SET_VALOR_ADICIONAL',
  RESET = 'RESET',
}

export interface DadosCalculoAntecipacao {
  readonly idSimulacao: number
  readonly valorBruto: number
  readonly txJurosEfetivaMensal: number
  readonly txJurosEfetivaAnual: number
  readonly txCetAnual: number
  readonly txCetMensal: number
  readonly valorJuros: number
  readonly valorLiquido: number
  readonly valorFinanciado: number
  readonly valorIof: number
  readonly diasCorridos: number
  readonly dtVencimento: string
}

export interface PropostasArray {
  id: string
  valorProposta: number
  status: 'EM_ANALISE' | 'APROVADO' | 'CANCELADO' | 'PAGO' | 'LIQUIDADO' | 'REPROVADO'
  dataCriacao: string
}

export interface PropostasEmAndamentoResponse {
  readonly propostas: PropostasArray[]
}

export interface ExistePropostasResponse {
  readonly cpf?: string
  readonly existePropostaAndamento: boolean
  readonly status: string
}

/**
 * State Type
 */

export interface AntecipacaoState {
  readonly valorRestituicao: number
  readonly score: number
  readonly loading: boolean
  readonly dadosCalculoAntecipacao: DadosCalculoAntecipacao
  readonly error: boolean
  readonly dadosPropostaAndamento: PropostasEmAndamentoResponse
  readonly presencaListaAntiFraude: boolean
  readonly decisao: string
  readonly existePropostaAndamento: boolean
  readonly valorAdicional: number
  readonly statusProposta: string
}

export interface PayloadConsultaRestituicao {
  cpf: string
  callback: (valorRestituicao: number) => void
  erro: (variantError: { title: string; description: string }) => void
}

export interface PayloadCalculoAntecipacao {
  valorDisponivelRestituicao: number
  score: number
  callback: () => void
}

export interface PayloadPropostasAndamento {
  callback: (existePropostaAndamento: boolean, status: string) => void
}

export interface PayloadAntifraude {
  cpf: string
  callback: () => void
  invalidCallback: () => void
}
