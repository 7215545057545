import { combineReducers } from 'redux'

import { DadosContaState } from './dadosConta/types'
import dadosConta from './dadosConta'
import { AntecipacaoState } from './antecipacao/types'
import antecipacao from './antecipacao'
import { AssinaturaState } from './assinatura/types'
import assinatura from './assinatura'

export interface ImpostoRendaState {
  dadosConta: DadosContaState
  antecipacao: AntecipacaoState
  assinatura: AssinaturaState
}

export default combineReducers({
  dadosConta,
  antecipacao,
  assinatura,
})
