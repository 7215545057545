import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Page } from '@templates'
import Paragraph from '@atoms/Paragraph'
import { Colors } from '@utils/Colors'
import { VerticalListNumber } from '@molecules'
import { Button } from '@interco/inter-ui/components/Button'
import {
  callPostCalculoAntecipacao,
  getValorRestituicao,
} from '@store/impostoRenda/antecipacao/actions'
import routes from '@routes/routes'
import { ApplicationState } from '@store/types'
import GoogleTag from '@utils/GoogleTag'

import TutorialAutorizacao from './TutorialAutorizacao'
import Bottomsheet from './Bottomsheet'
import { bottomsheetVariants } from './Bottomsheet/variants'

const stepsValidacao = [
  {
    title: 'Autorize o compartilhamento de dados do IR no site do e-CAC por um ano',
    description:
      'A autorização permite que a gente crie uma proposta customizada para você. Não precisa se preocupar, o Inter leva muito a sério a segurança dos seus dados.',
  },
  {
    title: 'Escolha a sua conta do Inter como conta de destino para a restituição',
    description:
      'A sua conta do Inter precisa estar selecionada como conta de destino da restituição.',
  },
  {
    title: 'Simule e escolha quanto você quer adiantar',
    description: 'Você pode adiantar até 100% da sua restituição do Imposto de Renda.',
  },
]
interface Variant {
  title: string
  description: string
  additionalValue?: boolean
}

const Validacao = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [showBottomsheet, setShowBottomsheet] = useState(false)
  const [variant, setVariant] = useState<Variant>(bottomsheetVariants.erroAntecipacao)
  const { cpf } = useSelector((state: ApplicationState) => state.impostoRenda.dadosConta)
  const { loading, score, valorAdicional } = useSelector(
    (state: ApplicationState) => state.impostoRenda.antecipacao,
  )

  const callSimular = () => {
    GoogleTag.impostoRendaTag({
      ref_figma: 3,
      content_id: 'Já autorizei e quero simular',
      content_action: 'clique',
      screen: 'Validação',
    })
    dispatch(
      getValorRestituicao({
        cpf: process.env.REACT_APP_MOCK_CPF_RESTITUICAO || cpf || '',
        callback: (valorRestituicao: number) => {
          dispatch(
            callPostCalculoAntecipacao({
              score,
              valorDisponivelRestituicao: valorRestituicao,
              callback: () => {
                navigate(routes.REVISAO_ANTECIPACAO)
              },
            }),
          )
        },
        erro: (variantError) => {
          setVariant(variantError)
          setShowBottomsheet(true)
        },
      }),
    )
  }
  return (
    <Page
      marginTop="-32px"
      footer={
        <>
          <Button fullWidth onClick={() => callSimular()} isLoading={loading}>
            Já autorizei e quero simular
          </Button>
          <Button
            fullWidth
            variant="secondary"
            onClick={() => {
              GoogleTag.impostoRendaTag({
                ref_figma: 3,
                content_id: 'Ver passo a passo para autorizar',
                content_action: 'clique',
                screen: 'Validação',
              })
              setOpen(true)
            }}
          >
            Ver passo a passo para autorizar
          </Button>
        </>
      }
    >
      <Paragraph
        fontSize="20px"
        lineHeight="25px"
        color={Colors.GRAY500}
        fontWeight={600}
        margin="0 0 32px 0"
      >
        Como faço para solicitar a antecipação?
      </Paragraph>
      <VerticalListNumber list={stepsValidacao} />
      {open ? <TutorialAutorizacao setOpen={() => setOpen(false)} /> : <></>}
      {showBottomsheet ? (
        <Bottomsheet
          onClose={() => setShowBottomsheet(false)}
          title={variant.title}
          description={variant.description}
          additionalValue={variant?.additionalValue}
          value={valorAdicional}
        />
      ) : (
        <></>
      )}
    </Page>
  )
}
export default Validacao
