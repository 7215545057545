import styled from 'styled-components'

import Paragraph from '@atoms/Paragraph'
import { Colors } from '@utils/Colors'

export const BlackText = styled.b`
  font-weight: 700;
`

export const OrangeText = styled.a`
  font-weight: 700;
  color: ${Colors.PRIMARY500};
  text-decoration: none;
`

export const ImageSteps = styled.img<{
  width?: string
  height?: string
  marginTop?: string
  marginBottom?: string
}>`
  width: ${({ width }) => width || '80%'};
  height: ${({ height }) => height || '80%'};
  margin-top: ${({ marginTop }) => marginTop || '0'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '0'};

  @media (min-height: 750px) and (orientation: portrait) {
    width: 98%;
    height: 98%;
  }

  ${({ marginTop }) =>
    marginTop &&
    `
     @media (orientation: landscape) {
      margin-top: 50px !important;
    }
  `}

  @media (orientation: landscape) {
    margin-top: 24px;
    width: 100%;
    max-width: 320px;
    max-height: 568px;
  }
  @media (min-height: 991px) {
    width: 100%;
    max-width: 400px;
    max-height: 710px;
  }
  align-self: center;
`

export const PSpace = styled(Paragraph)<{ marginTop?: string }>`
  margin-top: ${({ marginTop }) => marginTop || '20vh'};

  @media (orientation: landscape) {
    margin-top: 25vh !important;
  }

  ${({ marginTop }) =>
    marginTop &&
    `
    @media (orientation: landscape) {
      margin-top: 18vh !important;
    }
  `}
`

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const Footer = styled.div`
  margin-top: 15vh;
`
export const SpaceBetween = styled.div`
  height: 16px;
`
