import { Reducer } from 'redux'

import { AssinaturaState, AssinaturaTypes } from './types'

const INITIAL_STATE: AssinaturaState = {
  loading: false,
  checkTermos: false,
  enableButton: false,
  error: false,
  termo: '',
  termoDados: {
    codigoProposta: '',
    agencia: '',
    banco: '',
    contaCorrente: '',
    cpfConjuge: '',
    dataEmissao: '',
    documentoIdentificacao: '',
    endereco: '',
    estadoCivil: '',
    jurosCapitalizado: false,
    liberacaoCredito: '',
    nacionalidade: '',
    naturalidade: '',
    nomeConjuge: '',
    nomeMae: '',
    nomePai: '',
    previsaoPrimeiroDesconto: '',
    previsaoUltimoDesconto: '',
    qtdParcelasMensais: '',
    seguro: false,
    tarifaCadastro: '',
    valorParcelaMensal: 0,
    pracaDePagamento: '',
    valorGarantia: '',
    valorSeguro: '',
    termo: '',
    valorDoMutuo: '',
  },
}

const reducer: Reducer<AssinaturaState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AssinaturaTypes.SET_LOADING:
      return { ...state, loading: action.payload }
    case AssinaturaTypes.SET_CHECK_TERMOS:
      return { ...state, checkTermos: action.payload }
    case AssinaturaTypes.SET_ENABLE_BUTTON:
      return { ...state, enableButton: action.payload, error: false }
    case AssinaturaTypes.SET_ERROR:
      return { ...state, error: true }
    case AssinaturaTypes.SET_CODIGO_PROPOSTA:
      return { ...state, codigoProposta: action.payload }
    case AssinaturaTypes.SET_TERMO:
      return { ...state, termo: action.payload }
    case AssinaturaTypes.SET_TERMO_DADOS:
      return { ...state, termoDados: action.payload }
    case AssinaturaTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
