import React from 'react'

import { GridStyled, Paragraph } from '@atoms'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Colors } from '@utils/Colors'
import { PendingIcon } from '@icons/Signals'
import { CustomButton } from '@pages/Home/styles'

interface Props {
  redirect: () => void
  variant: {
    title: string
    descriptionFirstParagraph: string
    descriptionSecondParagraph?: string
    buttonText: string
  }
}
export const BottomSheetPropostas = ({ redirect, variant }: Props) => (
  <BottomSheet onClose={() => null} noSwipe>
    <GridStyled justify="space-around" margin="40px 0 0">
      <PendingIcon />
    </GridStyled>
    <Paragraph
      fontFamily="Sora"
      fontSize="16px"
      lineHeight="20px"
      color={Colors.GRAY500}
      fontWeight={600}
      margin="24px 0 8px 0"
    >
      {variant.title}
    </Paragraph>
    <Paragraph>{variant.descriptionFirstParagraph}</Paragraph>
    {variant.descriptionSecondParagraph && (
      <Paragraph>{variant.descriptionSecondParagraph}</Paragraph>
    )}
    <CustomButton fullWidth onClick={redirect}>
      {variant.buttonText}
    </CustomButton>
  </BottomSheet>
)
