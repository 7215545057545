import { call, put } from 'typed-redux-saga'

import { showError } from '@store/ui/error/actions'
import getPropostasEmAndamento from '@services/getPropostasEmAndamento'

import { callGetPropostasEmAndamento, propostasEmAndamentoSuccess, setLoading } from '../actions'

export default function* getPropostasEmAndamentoSaga() {
  try {
    yield put(setLoading(true))
    const { data } = yield* call(getPropostasEmAndamento)
    yield put(propostasEmAndamentoSuccess(data))
    yield put(setLoading(false))
  } catch (error) {
    yield put(setLoading(false))
    yield put(
      showError({
        title: 'Erro ao consultar propostas em andamento',
        message: 'Ocorreu um erro ao consultar propostas em andamento',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => callGetPropostasEmAndamento(),
        block: true,
      }),
    )
  }
}
