import { action, Action } from 'typesafe-actions'

import { NavigationTypes } from './types'

export const setIsApp = (payload: boolean): Action => action(NavigationTypes.SET_IS_APP, payload)
export const setOrigem = (payload: string): Action => action(NavigationTypes.SET_ORIGEM, payload)
export const setTitle = (payload: string): Action => action(NavigationTypes.SET_TITLE, payload)
export const setOrigemGoBack = (payload?: string): Action =>
  action(NavigationTypes.SET_ORIGEM_GO_BACK, payload)
export const setCurrentTab = (payload: string): Action =>
  action(NavigationTypes.SET_CURRENT_TAB, payload)
export const setEnableTab = (payload: boolean): Action =>
  action(NavigationTypes.SET_ENABLE_TAB, payload)
