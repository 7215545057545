import { AnyAction } from 'redux'
import { call, put } from 'typed-redux-saga'

import { showError } from '@store/ui/error/actions'
import getConsultaAntifraude from '@services/getConsultaAntifraude'

import {
  callConsultaAntifraude,
  setDecisao,
  setLoading,
  setPresencaListaAntiFraude,
  setScore,
} from '../actions'
import { PayloadAntifraude } from '../types'

export default function* getConsultaAntifraudeSaga(action: AnyAction) {
  const payload = action?.payload as PayloadAntifraude
  try {
    yield put(setLoading(true))
    const { data } = yield* call(getConsultaAntifraude, payload.cpf)
    yield put(setDecisao(data.decisao))
    yield put(setScore(data.score))
    yield put(setPresencaListaAntiFraude(data.presencaListaAntiFraude))
    yield put(setLoading(false))
    if (!data.presencaListaAntiFraude || data.decisao === 'Reprovado') {
      payload.invalidCallback()
    } else {
      payload.callback()
    }
  } catch (error) {
    yield put(setLoading(false))
    yield put(
      showError({
        title: 'Erro ao consultar análise de crédito',
        message: 'Ocorreu um erro ao consultar análise de crédito',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => callConsultaAntifraude(payload),
        block: true,
      }),
    )
  }
}
