import { action, Action } from 'typesafe-actions'

import { DadosContaTypes } from './types'

export const setTelefone = (payload: string): Action =>
  action(DadosContaTypes.SET_TELEFONE, payload)
export const setEmail = (payload: string): Action => action(DadosContaTypes.SET_EMAIL, payload)
export const setConta = (payload: string): Action => action(DadosContaTypes.SET_CONTA, payload)
export const setNome = (payload: string): Action => action(DadosContaTypes.SET_NOME, payload)
export const setCpf = (payload: string): Action => action(DadosContaTypes.SET_CPF, payload)
export const setDataNascimento = (payload: string): Action =>
  action(DadosContaTypes.SET_DATA_NASCIMENTO, payload)
export const setCheckTelefone = (payload: boolean): Action =>
  action(DadosContaTypes.SET_CHECK_TELEFONE, payload)
export const setSegmentation = (payload: string): Action =>
  action(DadosContaTypes.SET_SEGMENTATION, payload)
