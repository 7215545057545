import { Reducer } from 'redux'

import { NavigationState, NavigationTypes } from './types'

const INITIAL_STATE: NavigationState = {
  isApp: true,
  origem: 'app',
  title: 'Imposto de Renda',
  origemGoBack: undefined,
  currentTab: 'Simulador',
  enableTab: false,
}

const reducer: Reducer<NavigationState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NavigationTypes.SET_IS_APP:
      return { ...state, isApp: action.payload }
    case NavigationTypes.SET_ORIGEM:
      return { ...state, origem: action.payload }
    case NavigationTypes.SET_TITLE:
      return { ...state, title: action.payload }
    case NavigationTypes.SET_ORIGEM_GO_BACK:
      return { ...state, origemGoBack: action.payload }
    case NavigationTypes.SET_ENABLE_TAB:
      return { ...state, enableTab: action.payload }
    case NavigationTypes.SET_CURRENT_TAB:
      return { ...state, currentTab: action.payload }
    default:
      return state
  }
}

export default reducer
