import React from 'react'

import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Paragraph from '@atoms/Paragraph'
import { Colors } from '@utils/Colors'
import passoCinco from '@images/passoCinco.png'
import { Button } from '@interco/inter-ui/components/Button'
import routes from '@routes/routes'
import {
  callPostCalculoAntecipacao,
  getValorRestituicao,
} from '@store/impostoRenda/antecipacao/actions'
import { ApplicationState } from '@store/types'

import { Div, Footer, ImageSteps, SpaceBetween } from '../styles'

interface Props {
  setVariant: React.Dispatch<
    React.SetStateAction<{
      title: string
      description: string
    }>
  >
  setShowBottomsheet: (show: boolean) => void
}
export const PassoCinco = ({ setVariant, setShowBottomsheet }: Props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { cpf } = useSelector((state: ApplicationState) => state.impostoRenda.dadosConta)
  const { loading, score } = useSelector(
    (state: ApplicationState) => state.impostoRenda.antecipacao,
  )

  const callSimular = () => {
    dispatch(
      getValorRestituicao({
        cpf: process.env.REACT_APP_MOCK_CPF_RESTITUICAO || cpf || '',
        callback: (valorRestituicao: number) => {
          dispatch(
            callPostCalculoAntecipacao({
              score,
              valorDisponivelRestituicao: valorRestituicao,
              callback: () => {
                navigate(routes.REVISAO_ANTECIPACAO)
              },
            }),
          )
        },
        erro: (variantError) => {
          setVariant(variantError)
          setShowBottomsheet(true)
        },
      }),
    )
  }
  return (
    <Div>
      <ImageSteps src={passoCinco} alt="passo um" />
      <Paragraph
        fontSize="20px"
        lineHeight="25px"
        fontWeight={600}
        fontFamily="Sora"
        color={Colors.GRAY500}
        margin="32px 0 16px 0"
      >
        Agora, é só fazer sua simulação
      </Paragraph>
      <Paragraph>
        Depois de autorizar o Inter no site do e-CAC, comece sua simulação logo abaixo.
      </Paragraph>

      <Footer>
        <Button variant="primary" fullWidth onClick={() => callSimular()} isLoading={loading}>
          Já autorizei e quero simular
        </Button>
        <SpaceBetween />
        <Button variant="secondary" fullWidth onClick={() => navigate(routes.HOME)}>
          Vou autorizar depois
        </Button>
      </Footer>
    </Div>
  )
}
