import { action, Action } from 'typesafe-actions'

import {
  AntecipacaoTypes,
  DadosCalculoAntecipacao,
  PayloadAntifraude,
  PayloadCalculoAntecipacao,
  PayloadConsultaRestituicao,
  PayloadPropostasAndamento,
  PropostasEmAndamentoResponse,
} from './types'

export const setLoading = (payload: boolean): Action =>
  action(AntecipacaoTypes.SET_LOADING, payload)
export const setValorRestituicao = (payload: number): Action =>
  action(AntecipacaoTypes.SET_VALOR_RESTITUICAO, payload)
export const getValorRestituicao = (payload: PayloadConsultaRestituicao): Action =>
  action(AntecipacaoTypes.GET_VALOR_RESTITUICAO, payload)

export const callPostCalculoAntecipacao = (payload: PayloadCalculoAntecipacao): Action =>
  action(AntecipacaoTypes.POST_CALCULO_ANTECIPACAO, payload)
export const calculoAntecipacaoSuccess = (payload: DadosCalculoAntecipacao): Action =>
  action(AntecipacaoTypes.CALCULO_ANTECIPACAO_SUCESS, payload)
export const calculoAntecipacaoError = (): Action =>
  action(AntecipacaoTypes.CALCULO_ANTECIPACAO_ERROR)

export const callGetPropostasEmAndamento = (): Action =>
  action(AntecipacaoTypes.GET_PROPOSTAS_ANDAMENTO)
export const propostasEmAndamentoSuccess = (payload: PropostasEmAndamentoResponse): Action =>
  action(AntecipacaoTypes.PROPOSTAS_ANDAMENTO_SUCESS, payload)
export const propostasEmAndamentoError = (): Action =>
  action(AntecipacaoTypes.PROPOSTAS_ANDAMENTO_ERROR)

export const setScore = (payload: number): Action => action(AntecipacaoTypes.SET_SCORE, payload)
export const setDecisao = (payload: string): Action => action(AntecipacaoTypes.SET_DECISAO, payload)
export const setPresencaListaAntiFraude = (payload: boolean): Action =>
  action(AntecipacaoTypes.SET_PRESENCA_LISTA_ANTIFRAUDE, payload)
export const callConsultaAntifraude = (payload: PayloadAntifraude): Action =>
  action(AntecipacaoTypes.GET_CONSULTA_ANTIFRAUDE, payload)

export const callGetExisteProposta = (payload: PayloadPropostasAndamento): Action =>
  action(AntecipacaoTypes.GET_EXISTE_PROPOSTA, payload)
export const setExisteProposta = (payload: boolean): Action =>
  action(AntecipacaoTypes.SET_EXISTE_PROPOSTA, payload)
export const setStatusProposta = (payload: string): Action =>
  action(AntecipacaoTypes.SET_STATUS_PROPOSTA, payload)

export const setValorAdicional = (payload: number): Action =>
  action(AntecipacaoTypes.SET_VALOR_ADICIONAL, payload)
