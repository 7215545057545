import React from 'react'

import { useSelector } from 'react-redux'

import { cpfValue, formatCurrency } from '@interco/cp-react-ui-lib'
import { ApplicationState } from '@store/types'
import { TemplatesPDF } from '@interco/cp-react-ui-lib'
import useSignatureScroll from '@hooks/useSignatureScroll'
import { formatDate } from '@utils/functions'

import { TextCCB } from './Clausulas'
import { DocumentProps } from '../types'

const CCB = ({ pdfGridRef, setScrollDocument }: DocumentProps) => {
  const { nome, cpf, dataNascimento } = useSelector(
    (state: ApplicationState) => state.impostoRenda.dadosConta,
  )

  const { dadosCalculoAntecipacao } = useSelector(
    (state: ApplicationState) => state.impostoRenda.antecipacao,
  )
  const { termoDados } = useSelector((state: ApplicationState) => state.impostoRenda.assinatura)

  const variant = 'web'

  useSignatureScroll({ pdfGridRef, setScrollDocument })

  return (
    <TemplatesPDF.CedulaCreditoBancarioIR
      variant={variant}
      header={{
        title: 'CÉDULA DE CRÉDITO BANCÁRIO PESSOA FÍSICA ANTECIPAÇÃO DE RESTITUIÇÃO DE IR',
        numPropostaContrato: String(termoDados.codigoProposta),
      }}
      emitente={{
        cpf: cpfValue(cpf),
        nome,
        dataNascimento: dataNascimento || '',
        estadoCivil: termoDados?.estadoCivil,
        documentoIdentificacao: termoDados?.documentoIdentificacao,
        endereco: termoDados?.endereco,
        naturalidade: termoDados?.naturalidade,
        nacionalidade: termoDados?.nacionalidade,
        nomeMae: termoDados?.nomeMae,
        nomePai: termoDados?.nomePai,
        nomeConjuge: termoDados?.nomeConjuge,
        cpfConjuge: termoDados?.cpfConjuge,
        banco: termoDados?.banco,
        agencia: termoDados?.agencia,
        contaCorrente: termoDados?.contaCorrente,
      }}
      demonstrativoCalculo={{
        valorTotal: `${dadosCalculoAntecipacao.valorBruto}`,
        taxaJurosMensal: `${dadosCalculoAntecipacao.txJurosEfetivaMensal}%`,
        taxaJurosAnual: `${dadosCalculoAntecipacao.txJurosEfetivaAnual}%`,
        valorSolicitado: formatCurrency(Number(dadosCalculoAntecipacao.valorBruto)) || 'R$: 0,00',
        percentualValorSolicitado: ``,
        cetMensal: `${dadosCalculoAntecipacao.txCetMensal || 0}%`,
        cetAnual: `${dadosCalculoAntecipacao.txCetAnual || 0}%`,
        tarifaCadastro: `R$: ${termoDados?.tarifaCadastro}`,
        percentualTarifaCadastro: `0%`,
        dataEmissao: formatDate(termoDados.dataEmissao) || '',
        iof: formatCurrency(Number(dadosCalculoAntecipacao.valorIof)),
        percentualIof: `0%`,
        liberacaoCredito: termoDados?.liberacaoCredito,
        jurosContratados: formatCurrency(Number(dadosCalculoAntecipacao.valorJuros || 0)),
        percentualJurosContratados: `${dadosCalculoAntecipacao.txJurosEfetivaAnual || 0}%`,
        valorMutuo: formatCurrency(Number(termoDados.valorDoMutuo)),
        pracaPagamento: termoDados.pracaDePagamento,
        valorSeguro: formatCurrency(Number(termoDados.valorSeguro)),
        percentualValorSeguro: `${0}%`,
        seguro: termoDados?.seguro ? 'Sim' : 'Não',
        qtdeParcelasMensais: Number(termoDados?.qtdParcelasMensais),
        valorParcelaMensal: formatCurrency(Number(termoDados?.valorParcelaMensal)),
        jurosCapitalizados: termoDados?.jurosCapitalizado,
        previsaoPrimeiroDesconto: formatDate(termoDados?.previsaoPrimeiroDesconto) || '',
        previsaoUltimoDesconto: formatDate(termoDados?.previsaoUltimoDesconto) || '',
        valorGarantia: formatCurrency(Number(termoDados.valorGarantia)),
      }}
      textoAutorizacao={{ text: TextCCB }}
      assinatura={{
        isDgAssinado: true,
      }}
      footer={{
        codigo: 'CP032',
        edicao: '9a',
        atualizado: new Date('2022-07-01'),
        contatos: [
          'Central de Atendimento 3003-4070 (Capitais e Regiões Metropolitanas)',
          'SAC 0800 940 9999 - www.bancointer.com.br',
          'Ouvidoria 0800 940 7772 - Deﬁciente Fala e Audição 0800 979 7099',
        ],
      }}
    />
  )
}

export default CCB
