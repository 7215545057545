import React from 'react'

import Paragraph from '@atoms/Paragraph'
import { Colors } from '@utils/Colors'
import ImgUm from '@images/passoDois_img1.png'
import ImgDois from '@images/passoDois_img2.png'
import ImgTres from '@images/passoDois_img3.png'

import { BlackText, Div, ImageSteps } from '../styles'

export const PassoDois = () => (
  <Div>
    <Paragraph
      fontSize="20px"
      lineHeight="25px"
      fontWeight={600}
      fontFamily="Sora"
      color={Colors.GRAY500}
    >
      2. Selecione a opção abaixo
    </Paragraph>
    <Paragraph margin="0 0 24px 0">
      Na primeira tela após o login, selecione a quarta opção
      <BlackText>”Autorizar Compartilhamento de Dados”</BlackText>
    </Paragraph>
    <ImageSteps src={ImgUm} alt="imagem um" marginBottom="24px" />
    <Paragraph
      fontSize="20px"
      lineHeight="25px"
      fontWeight={600}
      fontFamily="Sora"
      color={Colors.GRAY500}
    >
      3. Crie uma nova autorização de compartilhamento
    </Paragraph>
    <Paragraph margin="0 0 24px 0">
      Na tela de autorização, selecione a opção{' '}
      <BlackText>”Nova autorização de compartilhamento de dados”</BlackText>
    </Paragraph>
    <ImageSteps src={ImgDois} alt="imagem dois" marginBottom="24px" />
    <Paragraph
      fontSize="20px"
      lineHeight="25px"
      fontWeight={600}
      fontFamily="Sora"
      color={Colors.GRAY500}
    >
      4. Siga as quatro etapas na sequência
    </Paragraph>
    <ImageSteps src={ImgTres} alt="imagem tres" marginBottom="24px" />
  </Div>
)
