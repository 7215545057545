import styled from 'styled-components'

import { Colors } from '@utils/Colors'

export const Wrap = styled.div`
  margin-bottom: 32px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 14px;
`
export const LeftWidth = styled.div`
  min-width: 12%;
`

export const Circle = styled.div`
  font-family: inter;
  color: ${Colors.WHITE};
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: ${Colors.GRAY500};
  font-size: 12px;
  font-weight: 600;
  margin-right: 10px;
`
export const RightWidth = styled.div`
  display: flex;
  flex-direction: column;
`
