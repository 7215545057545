import { action, Action } from 'typesafe-actions'

import {
  AssinaturaTypes,
  PayloadFinalizarProposta,
  PayloadAssinarProposta,
  TermosDados,
} from './types'

export const setLoading = (payload: boolean): Action => action(AssinaturaTypes.SET_LOADING, payload)
export const setEnableButton = (payload: boolean): Action =>
  action(AssinaturaTypes.SET_ENABLE_BUTTON, payload)
export const setCheckTermos = (payload: boolean): Action =>
  action(AssinaturaTypes.SET_CHECK_TERMOS, payload)
export const setError = (): Action => action(AssinaturaTypes.SET_ERROR)
export const setCodigoProposta = (payload: string): Action =>
  action(AssinaturaTypes.SET_CODIGO_PROPOSTA, payload)
export const postCallFinalizarProposta = (payload: PayloadFinalizarProposta): Action =>
  action(AssinaturaTypes.POST_FINALIZAR_PROPOSTA, payload)
export const setTermo = (payload: string): Action => action(AssinaturaTypes.SET_TERMO, payload)
export const postCallAssinarProposta = (payload: PayloadAssinarProposta): Action =>
  action(AssinaturaTypes.POST_ASSINAR_PROPOSTA, payload)
export const setTermoDados = (payload: TermosDados): Action =>
  action(AssinaturaTypes.SET_TERMO_DADOS, payload)
