import React, { useCallback, useEffect } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import InterWebviewBridge from '@interco/inter-webview-bridge'
import routes from '@routes/routes'
import { isOldBridge } from '@services/base/oldBridge'
import { ApplicationState } from '@store/types'
import { setCurrentTab } from '@store/ui/navigation/actions'

interface AppGoBackType {
  App: {
    goBack: () => void
  }
}
export type WindowMobileType = AppGoBackType & typeof window

export const GoBackConfig = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const iWb = InterWebviewBridge.getInstance()
  const Window = window as WindowMobileType
  const { currentTab } = useSelector((state: ApplicationState) => state.ui.navigation)

  const listenerMobileBack = useCallback(() => {
    if (
      (location?.pathname === routes.HOME && currentTab === 'Simulador') ||
      location?.pathname === routes.ANTECIPACAO_INDISPONIVEL
    ) {
      window.location.href = process.env.REACT_APP_HOME_EMPRESTIMO || ''
    } else if (location?.pathname === routes.HOME && currentTab === 'Area do Cliente') {
      dispatch(setCurrentTab('Simulador'))
    } else {
      navigate(-1)
    }
  }, [currentTab, dispatch, location?.pathname, navigate])

  useEffect(() => {
    if (isOldBridge()) {
      Window.App = {
        goBack: () => {
          try {
            if (
              location?.pathname === routes.HOME ||
              location?.pathname === routes.ANTECIPACAO_INDISPONIVEL
            ) {
              window.location.href = process.env.REACT_APP_HOME_EMPRESTIMO || ''
            } else if (location?.pathname === routes.HOME && currentTab === 'Area do Cliente') {
              dispatch(setCurrentTab('Simulador'))
            } else {
              navigate(-1)
            }
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e)
          }
        },
      }
    } else {
      iWb.addWebviewEventListener('GO_BACK', listenerMobileBack)
      return () => {
        iWb.removeWebviewEventListener('GO_BACK', listenerMobileBack)
      }
    }
    return () => null
  }, [Window, currentTab, dispatch, iWb, listenerMobileBack, location?.pathname, navigate])

  return <></>
}
