enum routes {
  HOME = '/home',
  VALIDACAO = '/validacao',
  SUCESSO = '/sucesso',
  RESULTADO_ANTECIPACAO = '/resultado-antecipacao',
  REVISAO_ANTECIPACAO = '/revisao-antecipacao',
  VISUALIZAR_DOCUMENTO = '/visualizar-documento',
  ANTECIPACAO_INDISPONIVEL = '/antecipacao-indisponivel',
}
export default routes
