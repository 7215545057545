/**
 * Actions Types
 */

export enum NavigationTypes {
  SET_IS_APP = '@navigation/SET_IS_APP',
  SET_ORIGEM = '@navigation/SET_ORIGEM',
  SET_TITLE = '@navigation/SET_TITLE',
  SET_ORIGEM_GO_BACK = '@navigation/SET_ORIGEM_GO_BACK',
  RESET = '@navigation/RESET',
  SET_CURRENT_TAB = '@navigation/SET_CURRENT_TAB',
  SET_ENABLE_TAB = '@navigation/SET_ENABLE_TAB',
}

/**
 * State Type
 */

export interface NavigationState {
  readonly isApp: boolean
  readonly origem: string
  readonly title: string
  readonly origemGoBack?: string
  readonly currentTab: string | number
  readonly enableTab: boolean
}
