import { BaseServices, HttpMethod } from '@services/base'

export default async (cpf: string) => {
  const headers = {
    'Content-Type': 'application/json',
    cpf,
  }

  const pathname = `${process.env.REACT_APP_CONSULTA_RESTITUICAO}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<{ valorRestituicao: number }>({
    headers,
    pathname,
    method,
    host,
  })

  return response
}
