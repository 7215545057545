import React from 'react'

import { Paragraph } from '@atoms'
import { Colors } from '@utils/Colors'

import { Circle, LeftWidth, RightWidth, Row, Wrap } from './styles'

interface ListT {
  title: string
  description: string
}
interface Props {
  list: ListT[]
}
export const VerticalListNumber = ({ list }: Props) => (
  <Wrap>
    {list.map((item, index) => (
      <Row key={item.title}>
        <LeftWidth>
          <Circle>{index + 1}</Circle>
        </LeftWidth>
        <RightWidth>
          <Paragraph color={Colors.GRAY500} fontWeight={700}>
            {item.title}
          </Paragraph>
          <Paragraph>{item.description}</Paragraph>
        </RightWidth>
      </Row>
    ))}
  </Wrap>
)
