import React from 'react'

import { Paragraph } from '@atoms'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Colors } from '@utils/Colors'
import { Button } from '@interco/inter-ui/components/Button'

interface Props {
  setShowBottomSheetCet: React.Dispatch<React.SetStateAction<boolean>>
}
export const BottomSheetCet = ({ setShowBottomSheetCet }: Props) => (
  <BottomSheet onClose={() => setShowBottomSheetCet(false)}>
    <Paragraph
      fontFamily="Sora"
      fontSize="16px"
      lineHeight="20px"
      color={Colors.GRAY500}
      fontWeight={600}
      margin="24px 0 8px 0"
    >
      Entenda o Custo Efetivo Total (CET)
    </Paragraph>

    <Paragraph margin="8px 0 24px 0">
      O Custo Efetivo Total (CET) contempla todos os custos envolvidos no empréstimo, como taxa de
      juros, IOF e outros.
    </Paragraph>
    <Button fullWidth onClick={() => setShowBottomSheetCet(false)}>
      Entendi
    </Button>
  </BottomSheet>
)
