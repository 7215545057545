import { AnyAction } from 'redux'
import { call, put } from 'typed-redux-saga'

import getConsultaRestituicao from '@services/getConsultaRestituicao'
import { HttpStatus } from '@services/base'
import { bottomsheetVariants } from '@pages/Validacao/Bottomsheet/variants'

import { PayloadConsultaRestituicao } from '../types'
import { setLoading, setValorAdicional, setValorRestituicao } from '../actions'

interface ErrorType {
  data: {
    tipoErro: string
    detalhes: string
  }
  status: HttpStatus
}

export default function* getConsultaRestituicaoSaga(action: AnyAction) {
  const payload = action?.payload as PayloadConsultaRestituicao
  try {
    yield put(setLoading(true))
    const { data } = yield* call(getConsultaRestituicao, payload.cpf)
    yield put(setValorRestituicao(data.valorRestituicao))
    yield put(setLoading(false))
    payload.callback(data.valorRestituicao)
  } catch (error) {
    yield put(setLoading(false))
    const errorResponse = error as ErrorType
    if (errorResponse?.status === HttpStatus.PRECONDITION_FAILED) {
      if (errorResponse?.data?.tipoErro === 'VALOR_MINIMO_RESTITUICAO') {
        yield put(setValorAdicional(Number(errorResponse?.data?.detalhes)))
        yield payload?.erro(bottomsheetVariants.valorInsuficiente)
      } else if (errorResponse?.data?.tipoErro === 'BANCO_RECEBEDOR_DIFERENTE_INTER') {
        yield payload?.erro(bottomsheetVariants.contaInvalida)
      } else if (errorResponse?.data?.tipoErro === 'DECLARACAO_NAO_FINALIZADA') {
        yield payload?.erro(bottomsheetVariants.declaracaoNaoFinalizada)
      } else if (errorResponse?.data?.tipoErro === 'VALOR_MAXIMO_RESTITUICAO') {
        yield put(setValorAdicional(Number(errorResponse?.data?.detalhes)))
        yield payload?.erro(bottomsheetVariants.valorMaximo)
      } else if (errorResponse?.data?.tipoErro === 'NENHUMA_AUTORIZACAO_ENCONTRADA') {
        yield payload?.erro(bottomsheetVariants.nenhumaAutorizacaoEncontrada)
      }
    } else {
      yield payload?.erro(bottomsheetVariants.erroAntecipacao)
    }
  }
}
