import React from 'react'

import Paragraph from '@atoms/Paragraph'
import { Colors } from '@utils/Colors'
import ImgUm from '@images/passoQuatro_img1.png'
import ImgDois from '@images/passoQuatro_img2.png'

import { BlackText, Div, ImageSteps, OrangeText } from '../styles'

export const PassoQuatro = () => (
  <Div>
    <Paragraph
      fontSize="20px"
      lineHeight="25px"
      fontWeight={600}
      fontFamily="Sora"
      color={Colors.GRAY500}
    >
      3º Etapa
    </Paragraph>
    <Paragraph margin="0 0 24px 0">
      Selecione o <BlackText>banco inter</BlackText> como destinatário.
    </Paragraph>
    <ImageSteps src={ImgUm} alt="imagem um" marginBottom="24px" />
    <Paragraph
      fontSize="20px"
      lineHeight="25px"
      fontWeight={600}
      fontFamily="Sora"
      color={Colors.GRAY500}
    >
      4º Etapa
    </Paragraph>
    <Paragraph margin="0 0 24px 0">
      Leia os termos e clique no botão <BlackText>”Autorizar”</BlackText> no canto inferior direito
      da tela.
    </Paragraph>
    <ImageSteps src={ImgDois} alt="imagem dois" />
    <Paragraph margin="0 0 24px 0">
      Tudo certo? Lembrando que esses passos devem ser executados no{' '}
      <OrangeText href={process.env.REACT_APP_SITE_ECAC}>site do e-CAC</OrangeText> ok?
    </Paragraph>
  </Div>
)
