import styled from 'styled-components'

import { Checkbox } from '@interco/inter-ui/components/Checkbox'
import { Colors } from '@utils/Colors'

export const LineDetailsDescription = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-content: center;
  align-items: center;
`
export const CheckboxCustom = styled(Checkbox)`
  & label {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`
export const LinkTermoAutorizacao = styled.b`
  color: ${Colors.PRIMARY500};
  font-weight: 700;
`
export const Span = styled.span`
  vertical-align: middle;
  margin-left: 6px;
`
