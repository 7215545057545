import React from 'react'

import Paragraph from '@atoms/Paragraph'
import { Colors } from '@utils/Colors'
import passoUm from '@images/passoUm.png'

import { Div, ImageSteps, OrangeText } from '../styles'

export const PassoUm = () => (
  <Div>
    <Paragraph
      fontSize="20px"
      lineHeight="25px"
      fontWeight={600}
      fontFamily="Sora"
      color={Colors.GRAY500}
    >
      1. Faça login no site do e-CAC
    </Paragraph>
    <Paragraph margin="0 0 16px 0">
      Acesse o <OrangeText href={process.env.REACT_APP_SITE_ECAC}>site do e-CAC</OrangeText> e faça
      o seu login. Você poderá escolher entre logar com a sua conta do e-CAC ou com a sua conta do
      gov.br
    </Paragraph>
    <ImageSteps src={passoUm} alt="passo um" />
  </Div>
)
