import React, { useEffect, useState } from 'react'

import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { clearError } from '@store/ui/error/actions'
import { Paragraph } from '@atoms'
import { Colors } from '@utils/Colors'

import { connector, Props } from './types'
import { Header, LargeButton } from './styles'

const ErrorMessage = ({
  showError = false,
  title = 'Ocorreu um erro',
  message = 'Ocorreu um erro inesperado, tente novamente.',
  code,
  actionTitle = 'Entendi',
  actionCallback,
  block,
  dispatch,
}: Props) => {
  const [open, setOpen] = useState(showError)

  useEffect(() => {
    dispatch(clearError())
  }, [dispatch])

  useEffect(() => {
    setOpen(showError)
  }, [showError, setOpen])

  if (open) {
    return (
      <BottomSheet
        noSwipe={Boolean(block)}
        onClose={
          !block
            ? () => {
                setOpen(false)
                setTimeout(() => {
                  dispatch(clearError())
                }, 600)
              }
            : () => null
        }
      >
        <Header>
          <Paragraph
            fontFamily="sora"
            fontSize="1rem"
            lineHeight="1.25rem"
            color={Colors.GRAY500}
            fontWeight={600}
          >
            {title}
          </Paragraph>
        </Header>
        <Paragraph margin={code ? 'unset' : '0 0 15px 0;'}>{message}</Paragraph>
        {code && <Paragraph margin="0 0 15px 0;">{`COD (${code})`}</Paragraph>}

        <LargeButton
          onClick={() => {
            setOpen(false)
            setTimeout(() => {
              dispatch(clearError())
              if (actionCallback instanceof Function) {
                dispatch(actionCallback())
              }
            }, 600)
          }}
        >
          {actionTitle}
        </LargeButton>
      </BottomSheet>
    )
  }
  return <></>
}

export default connector(ErrorMessage)
