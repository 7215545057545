import styled from 'styled-components'

import { Colors } from '@utils/Colors'

export const PdfGrid = styled.div`
  overflow: scroll;
  height: calc(100vh - 162px);
  ::-webkit-scrollbar {
    display: none;
  }
`

export const FooterGrid = styled.div`
  padding: 0;
  background-color: transparent;
  margin-top: 16px;
`

export const PageVisualizarPdf = styled.div`
  height: auto;
  margin: 24px;
`

export const Div = styled.div`
  div[role='dialog']:last-child {
    display: none;
  }
  div[role='dialog'] {
    background-color: ${Colors.GRAY500};
    display: grid;
    padding: 16px;
    & > div {
      & > div:nth-child(2) {
        margin: 0 16px;
        & > span {
          color: ${Colors.WHITE};
        }
        & > div > p {
          color: ${Colors.WHITE};
        }
      }
    }
    & > div:last-child {
      & > span {
        background-color: ${Colors.PRIMARY500};
        color: ${Colors.WHITE};
        border-radius: 8px;
        padding: 10px 16px;
        font-family: 'inter';
        font-weight: 600;
        font-size: 12px;
      }
    }
  }
`
