import React from 'react'

import { useNavigate } from 'react-router-dom'

import { Button } from '@interco/inter-ui/components/Button'
import GridStyled from '@atoms/GridStyled'
import Paragraph from '@atoms/Paragraph'
import { Colors } from '@utils/Colors'
import { Page } from '@templates'
import { ErrorIcon } from '@icons/Signals'

const AntecipacaoIndisponivel = () => {
  const navigate = useNavigate()
  return (
    <Page
      footer={
        <Button variant="primary" onClick={() => navigate(-1)}>
          Entendi
        </Button>
      }
    >
      <GridStyled justify="space-around" margin="40px 0 0">
        <ErrorIcon />
      </GridStyled>

      <Paragraph
        variant="sora"
        fontSize="24px"
        fontWeight="600"
        lineHeight="28px"
        color={Colors.GRAY500}
        textAlign="center"
        margin="40px 0 8px"
      >
        Crédito indisponível
      </Paragraph>
      <Paragraph textAlign="center" variant="inter">
        No momento, não foi possível te oferecer a Antecipação de Imposto de Renda devido a algumas
        políticas internas do Inter.
      </Paragraph>
    </Page>
  )
}

export default AntecipacaoIndisponivel
