import { TermosDados } from '@store/impostoRenda/assinatura/types'

import { BaseServices, HttpMethod } from './base'

export default (codigoProposta: string) => {
  const pathname = `${process.env.REACT_APP_ASSINAR_PROPOSTA}?codigoProposta=${codigoProposta}`
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<TermosDados>({
    pathname,
    method,
    host,
  })
  return response
}
