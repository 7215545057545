import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { Box, GridStyled, Loading, Paragraph } from '@atoms'
import { Colors } from '@utils/Colors'
import Receipt from '@interco/icons/orangeds/MD/receipt'
// import ChevronRight from '@interco/icons/orangeds/MD/chevron-right'
import { formatCurrency, formatDate } from '@utils/functions'
import { callGetPropostasEmAndamento } from '@store/impostoRenda/antecipacao/actions'
import { ApplicationState } from '@store/types'
import { tagOption } from '@utils/constants'
import { Alert } from '@interco/inter-ui/components/Alert'

import { CustomTag, IconArea, TextArea } from '../styles'

// const FilterTypes = {
//   PROPOSTAS: 'Propostas',
//   CONTRATOS: 'Contratos',
//   CONCLUIDOS: 'Concluidos',
//   CANCELADOS: 'Cancelados',
// }

const AreaCliente = () => {
  const dispatch = useDispatch()
  // const [filterActive, setFilterActive] = useState('Propostas')
  const { dadosPropostaAndamento, loading } = useSelector(
    (state: ApplicationState) => state.impostoRenda.antecipacao,
  )

  useEffect(() => {
    dispatch(callGetPropostasEmAndamento())
  }, [dispatch])

  if (!dadosPropostaAndamento.propostas.length) {
    return <Alert type="warning" title="Não há propostas a serem exibidas" />
  }

  return (
    <>
      <Paragraph
        fontFamily="sora"
        fontSize="20px"
        lineHeight="25px"
        color={Colors.GRAY500}
        fontWeight={600}
      >
        Suas propostas de antecipação de IR
      </Paragraph>
      {/* <FilterLine>
        <CustomChips
          label="Propostas"
          mode="normal"
          isActive={filterActive === FilterTypes.PROPOSTAS}
          onClick={() => setFilterActive(FilterTypes.PROPOSTAS)}
        />
        <CustomChips
          label="Contratos"
          mode="normal"
          isActive={filterActive === FilterTypes.CONTRATOS}
          onClick={() => setFilterActive(FilterTypes.CONTRATOS)}
        />
        <CustomChips
          label="Concluídos"
          mode="normal"
          isActive={filterActive === FilterTypes.CONCLUIDOS}
          onClick={() => setFilterActive(FilterTypes.CONCLUIDOS)}
        />
        <CustomChips
          label="Cancelados"
          mode="normal"
          isActive={filterActive === FilterTypes.CANCELADOS}
          onClick={() => setFilterActive(FilterTypes.CANCELADOS)}
        />
      </FilterLine> */}
      {loading ? (
        <GridStyled>
          <Loading width={50} height={50} />
        </GridStyled>
      ) : (
        <>
          {dadosPropostaAndamento.propostas.map((proposta) => (
            <Box
              justify="space-between"
              padding="16px"
              align="flex-start"
              margin="16px 0"
              key={proposta.id}
            >
              <IconArea>
                <Receipt width={24} height={24} color={Colors.PRIMARY500} />
              </IconArea>
              <TextArea>
                <Paragraph color={Colors.GRAY500} fontWeight={700}>
                  Proposta - {formatCurrency(proposta.valorProposta)}
                </Paragraph>
                <Paragraph>Data de criação: {formatDate(proposta.dataCriacao)}</Paragraph>
                <CustomTag
                  color={tagOption[proposta.status].bgColor}
                  colorText={tagOption[proposta.status].nameColor}
                  size="small"
                >
                  {tagOption[proposta.status].nameDescription}
                </CustomTag>
              </TextArea>
              <div />
              {/* <ChevronRight width={24} height={24} style={{ alignSelf: 'center' }} /> */}
            </Box>
          ))}
        </>
      )}
    </>
  )
}
export default AreaCliente
