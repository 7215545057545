import React from 'react'

import { Paragraph } from '@atoms'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Colors } from '@utils/Colors'
import { Button } from '@interco/inter-ui/components/Button'

interface Props {
  setShowBottomSheetValor: React.Dispatch<React.SetStateAction<boolean>>
}
export const BottomSheetValor = ({ setShowBottomSheetValor }: Props) => (
  <BottomSheet onClose={() => setShowBottomSheetValor(false)}>
    <Paragraph
      fontFamily="Sora"
      fontSize="16px"
      lineHeight="20px"
      color={Colors.GRAY500}
      fontWeight={600}
      margin="24px 0 8px 0"
    >
      Valor disponível pra Antecipação
    </Paragraph>

    <Paragraph margin="8px 0 24px 0">
      Esse é o valor total disponível para antecipação da sua restituição junto ao Inter.
    </Paragraph>
    <Paragraph margin="8px 0 24px 0">
      Caso esse valor seja menor que o total da sua restituição, o restante será depositado
      normalmente no dia do seu respectivo lote.
    </Paragraph>
    <Button fullWidth onClick={() => setShowBottomSheetValor(false)}>
      Entendi
    </Button>
  </BottomSheet>
)
