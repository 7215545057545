import { AnyAction } from 'redux'
import { call, put } from 'typed-redux-saga'

import { showError } from '@store/ui/error/actions'
import postAssinarProposta from '@services/postAssinarProposta'

import { postCallAssinarProposta, setError, setLoading } from '../actions'
import { PayloadAssinarProposta } from '../types'

export default function* postAssinarPropostaSaga(action: AnyAction) {
  const payload = action?.payload as PayloadAssinarProposta
  try {
    yield put(setLoading(true))
    yield* call(postAssinarProposta, payload.codigoProposta)
    yield put(setLoading(false))
    payload.callback()
  } catch (error) {
    yield put(setLoading(false))
    yield put(setError())
    yield put(
      showError({
        title: 'Erro ao buscar o termo',
        message: 'Ocorreu um erro ao buscar o termo',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => postCallAssinarProposta(payload),
        block: true,
      }),
    )
  }
}
