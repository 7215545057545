import { Reducer } from 'redux'

import { AntecipacaoState, AntecipacaoTypes } from './types'

const INITIAL_STATE: AntecipacaoState = {
  loading: false,
  valorRestituicao: 0,
  dadosCalculoAntecipacao: {
    idSimulacao: 0,
    valorBruto: 0,
    txJurosEfetivaMensal: 0,
    txJurosEfetivaAnual: 0,
    txCetAnual: 0,
    txCetMensal: 0,
    valorJuros: 0,
    valorLiquido: 0,
    valorFinanciado: 0,
    valorIof: 0,
    diasCorridos: 0,
    dtVencimento: '',
  },
  error: false,
  dadosPropostaAndamento: {
    propostas: [],
  },
  existePropostaAndamento: false,
  presencaListaAntiFraude: false,
  decisao: '',
  score: 0,
  valorAdicional: 300,
  statusProposta: '',
}

const reducer: Reducer<AntecipacaoState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AntecipacaoTypes.SET_LOADING:
      return { ...state, loading: action.payload }
    case AntecipacaoTypes.SET_VALOR_RESTITUICAO:
      return { ...state, valorRestituicao: action.payload }
    case AntecipacaoTypes.CALCULO_ANTECIPACAO_SUCESS:
      return { ...state, dadosCalculoAntecipacao: action.payload, error: false }
    case AntecipacaoTypes.CALCULO_ANTECIPACAO_ERROR:
      return { ...state, error: true }
    case AntecipacaoTypes.PROPOSTAS_ANDAMENTO_SUCESS:
      return { ...state, dadosPropostaAndamento: action.payload, error: false }
    case AntecipacaoTypes.PROPOSTAS_ANDAMENTO_ERROR:
      return { ...state, error: true }
    case AntecipacaoTypes.SET_SCORE:
      return { ...state, score: action.payload }
    case AntecipacaoTypes.SET_DECISAO:
      return { ...state, decisao: action.payload }
    case AntecipacaoTypes.SET_PRESENCA_LISTA_ANTIFRAUDE:
      return { ...state, presencaListaAntiFraude: action.payload }
    case AntecipacaoTypes.SET_EXISTE_PROPOSTA:
      return { ...state, existePropostaAndamento: action.payload }
    case AntecipacaoTypes.SET_VALOR_ADICIONAL:
      return { ...state, valorAdicional: action.payload }
    case AntecipacaoTypes.SET_STATUS_PROPOSTA:
      return { ...state, statusProposta: action.payload }
    case AntecipacaoTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
