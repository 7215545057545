import React from 'react'

import Paragraph from '@atoms/Paragraph'
import { Colors } from '@utils/Colors'
import ImgUm from '@images/passoTres_img1.png'
import ImgDois from '@images/passoTres_img2.png'

import { BlackText, Div, ImageSteps } from '../styles'

export const PassoTres = () => (
  <Div>
    <Paragraph
      fontSize="20px"
      lineHeight="25px"
      fontWeight={600}
      fontFamily="Sora"
      color={Colors.GRAY500}
    >
      1º Etapa
    </Paragraph>
    <Paragraph margin="0 0 24px 0">
      No primeiro passo selecione{' '}
      <BlackText>”informações sobre restituição de imposto de renda”</BlackText>
    </Paragraph>
    <ImageSteps src={ImgUm} alt="imagem um" marginBottom="24px" />
    <Paragraph
      fontSize="20px"
      lineHeight="25px"
      fontWeight={600}
      fontFamily="Sora"
      color={Colors.GRAY500}
    >
      2º Etapa
    </Paragraph>
    <Paragraph margin="0 0 24px 0">
      Depois, selecione <BlackText>”Por prazo definido de”</BlackText> e, em seguida, escolha{' '}
      <BlackText> a data de hoje até 12 meses futuros a partir da data de solicitação</BlackText>
    </Paragraph>
    <ImageSteps src={ImgDois} alt="imagem dois" />
  </Div>
)
