import { BaseServices, HttpMethod } from '@services/base'

export default async (cpf: string) => {
  const headers = {
    'Content-Type': 'application/json',
    cpf,
  }

  const pathname = `${process.env.REACT_APP_CONSULTA_ANTI_FRAUDE}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST

  const response = BaseServices.request<{
    presencaListaAntiFraude: boolean
    score: number
    decisao: string
  }>({
    headers,
    pathname,
    method,
    host,
  })

  return response
}
