import React from 'react'

export const TextCCB = (
  <>
    O(A) <b>EMITENTE</b> declara que, previamente à emissão desta Cédula de Crédito Bancário (“
    <b>CCB</b>”), tomou ciência e anuiu quanto à Proposta de Crédito, que contém taxa de juros,
    valor e datas de liberação do crédito solicitado, fluxo de pagamentos, tributos, tarifas e
    seguro prestamista (se contratado), elementos que compõem o custo efetivo total (“<b>CET</b>
    ”) do crédito. <b>01</b>. O(A) <b>EMITENTE</b> promete pagar em favor do <b>CREDOR</b>, na praça
    de pagamento, nas datas de vencimento, nos termos e nas condições dispostos nesta CCB, o valor
    líquido, certo e exigível do mútuo (item 2.14), acrescido do valor de IOF e demais encargos
    aplicáveis e/ou previstos nesta <b>CCB</b>. 01.1. O(A) <b>EMITENTE</b> promete ainda pagar ao{' '}
    <b>CREDOR</b> as despesas necessárias à formalização desta <b>CCB</b> e eventuais despesas de
    conservação e exigência do mútuo e das garantias ofertadas, tais como honorários de cobrança
    administrativa, desde já fixados em 10% (dez por cento) do valor do saldo devedor, custas de
    protesto, taxas e emolumentos cartoriais, despesas com escrituras públicas e tributos
    incidentes. <b>02</b>. O valor do mútuo será disponibilizado diretamente pelo <b>CREDOR</b> em
    conta corrente bancária de titularidade do(a) <b>EMITENTE</b> (item 1.15. <b>03</b>. Para
    assegurar o cumprimento das obrigações decorrentes desta <b>CCB</b>, o(a) <b>EMITENTE</b>{' '}
    constitui em garantia cessão fiduciária, em favor do <b>CREDOR</b>, sobre a totalidade do(s)
    direito(s) de crédito futuro(s), relativos ao(s){' '}
    <b>Restituição do Imposto de Renda Pessoa Física</b>, a ser depositado(s) na(s) conta(s) de sua
    titularidade (item 1.15) e indicada no recibo de declaração (“<b>Cessão Fiduciária</b>
    ”). <b>04</b>. Se o(a) <b>EMITENTE</b> não indicou, para ﬁns de Restituição do Imposto de Renda,
    a conta descrita no Quadro 1, deverá providenciar a indicação por Declaração Retiﬁcadora, logo
    após a assinatura desta, sendo vedada a alteração posterior para banco diverso, salvo se
    liquidar antecipadamente. A não indicação daquela conta, enquanto perdurar este contrato, dará
    ensejo ao vencimento antecipado da dívida e adoção das medidas que couberem. <b>05</b>.O valor
    do mútuo (item 2.14) deverá ser acrescido do valor do IOF, com a incidência, sobre todas as
    parcelas, da taxa de juros prevista (item 2.13), calculada no período compreendido entre a data
    de emissão desta <b>CCB</b> e as datas dos vencimentos das parcelas ou, em caso de mora, até o
    dia da quitação de todo o saldo devedor, observados os limites estipulados em lei.{' '}
    <b>
      06. O pagamento do valor do mútuo e seus encargos será realizado em parcela única (itens 2.19
      e 2.20), com vencimento na data do recebimento da restituição do(a) EMITENTE, ou no vencimento
      final da CCB (item 2.22), o que ocorrer primeiro. 07.
    </b>{' '}
    Se, por qualquer motivo, o saldo em conta corrente do(a) <b>EMITENTE</b> for insuficiente ou não
    puder ser utilizado para quitação e/ou amortização do saldo devedor, o(a) <b>EMITENTE</b>
    autoriza o <b>CREDOR</b>, desde já, em caráter irrevogável e irretratável, a deduzir o débito
    total ou parcial das parcelas devidas na(s) conta(s) corrente(s) de sua titularidade (itens 1.13
    a 1.15). No caso de impossibilidade de dedução do débito em conta(s) corrente(s), o(a){' '}
    <b>EMITENTE</b>, agindo com base na boa-fé (art. 4º, inciso III, Lei 8.078/90), se compromete a
    solicitar ao <b>CREDOR</b> de imediato a emissão de boleto bancário para quitar o débito.
    Sucessivamente, o <b>CREDOR</b>, a seu critério, já expressamente autorizado pelo(a){' '}
    <b>EMITENTE</b>, alongará automaticamente o prazo do empréstimo, sem alterar o valor da soma das
    parcelas e do juros previamente acordados. <b>07.1. </b>
    Fica explicitado que, ainda que haja saldo bastante, a não efetivação do débito prevista na
    cláusula anterior ou a sua efetivação parcial não tirará a certeza, a liquidez e a exigibilidade
    da dívida. <b>08</b>. O <b>CREDOR</b> poderá, a seu exclusivo critério, considerar
    antecipadamente vencida esta <b>CCB</b>, independentemente de aviso, notificação ou interpelação
    judicial ou extrajudicial, caso configurada qualquer das hipóteses legais ou das seguintes
    situações: <b>a)</b> falta ou atraso no pagamento, pelo(a) <b>EMITENTE</b>, de quaisquer
    obrigações pecuniárias previstas nesta CCB; <b>b)</b> encerramento de conta(s) bancária(s) do(a){' '}
    <b>EMITENTE</b>, por violações de normas ou instruções do BCB ou do CMN;
    <b>c)</b> ocorrência superveniente de fatos que impossibilitem a execução ou diminuam o valor da
    garantia prestada à essa <b>CCB</b>, e, notificado o(a) <b>EMITENTE</b>, não seja providenciado
    o reforço ou a substituição da garantia no prazo de 15 (quinze) dias; <b>d)</b>
    ciência, do <b>CREDOR</b>, de qualquer processo judicial e/ou administrativo, investigação por
    parte de órgãos policiais, Ministério Público, órgão reguladores e/ou congêneres, no qual o(a){' '}
    <b>EMITENTE</b> seja relacionado a qualquer operação ou ação ilegal, tais como corrupção,
    lavagem de dinheiro, crime ambiental; <b>e)</b> falsidade de declaração, informação ou documento
    materialmente relevante que houver sido prestada, entregue ou firmado pelo(a) <b>EMITENTE</b>;{' '}
    <b>g)</b> transferência, cessão ou promessa de cessão a terceiros dos direitos e obrigações
    decorrentes desta <b>CCB</b>, por parte do(a) <b>EMITENTE</b>, sem a prévia e expressa anuência
    do <b>CREDOR</b>; <b>h)</b> Publicação de norma legal ou regulamentar que inviabilize, direta ou
    indiretamente, a continuidade desta CCB. <b>i)</b> execução de dívida ou legítimo protesto de
    qualquer dívida do(a) <b>EMITENTE</b>.{' '}
    <b>
      09. Não obstante o vencimento antecipado das obrigações desta <b>CCB</b> em caso de atraso no
      pagamento das obrigações pecuniárias estabelecidas, incidirá sobre o saldo devedor do mútuo,
      da data do vencimento da obrigação até o dia do efetivo pagamento da integralidade do débito,
      além dos juros remuneratórios previstos (item 2.13), juros de mora de 1% ao mês, calculados
      pro rata die, e multa de 2% sobre a dívida (Res. 4.882/20 do BCB). 09.1
    </b>{' '}
    Caso ocorra mora e/ou inadimplemento no cumprimento de qualquer das obrigações previstas nesta{' '}
    <b>CCB</b>, bem como se verifique alguma das hipóteses de vencimento antecipado, estará o{' '}
    <b>CREDOR</b>, de pleno direito, autorizado pelo(a) <b>EMITENTE</b> a registrar o nome deste(a)
    nos cadastros de proteção ao crédito e registro de inadimplência, tais como SERASA/SPC/CDL, e na
    Central de Riscos do BCB, sem prejuízo da prerrogativa de determinar o protesto desta{' '}
    <b>CCB. 09.2.</b> Vencida a dívida ou na ocorrência de vencimento antecipado, o(a){' '}
    <b>EMITENTE</b> autoriza, desde já, o <b>CREDOR</b>, em caráter irrevogável e irretratável, a
    promover a compensação da dívida contraída, nos termos desta <b>CCB</b>, com eventuais créditos
    que tenha ou venha a ter junto ao CREDOR ou a instituições conveniadas, inclusive créditos em
    conta corrente e aplicações financeiras, bem com aqueles decorrentes de excesso de garantias de
    outros ajustes firmados com o <b>CREDOR</b>, conferindo a este, neste ato, os necessários
    poderes para proceder ao resgate ou negociação de títulos, em valor suficiente para a liquidação
    do saldo devedor da divida existente. <b>10.</b> Fica assegurado ao(à) <b>EMITENTE</b> o direito
    de liquidar antecipadamente o débito contraído junto ao <b>CREDOR</b>, (art. 52, § 2º, Lei nº
    8.078/90). <b>10.1.</b> O <b>CREDOR</b> obriga-se a disponibilizar a planilha de cálculo do
    saldo devedor e/ou o boleto para a liquidação antecipada do débito contratado, sempre que
    solicitado pelo(a) <b>EMITENTE</b>, por meio da Central de Atendimento ou pelo “Fale Conosco”,
    disponível no site informado no rodapé desta <b>CCB. 11.</b> O(A) <b>EMITENTE</b> poderá efetuar
    a portabilidade desta operação de crédito para outra instituição financeira. Para tanto, deverá
    formalizar a solicitação junto à instituição escolhida. <b>12.</b> Eventual tolerância do{' '}
    <b>CREDOR</b> com relação ao não cumprimento ou à mora no cumprimento das obrigações e dos
    prazos desta
    <b>CCB</b> não importará em novação ou desistência, nem poderá ser invocada pelo(a)
    <b>EMITENTE</b>, sob hipótese alguma, ficando assegurados ao <b>CREDOR</b> (i) todos os seus
    direitos, que poderão ser exercidos a qualquer tempo; e (ii) a prerrogativa de tomar as medidas
    judiciais ou extrajudiciais cabíveis, para a exigência do cumprimento de seus direitos.{' '}
    <b>13.</b> O(A) <b>EMITENTE</b> reconhece que esta <b>CCB</b> representa título líquido, certo e
    exigível, pelo valor do saldo apurado em conformidade com suas cláusulas e condições, e
    reconhece a sua força executiva, seja pelo seu próprio teor seja pela sua integração, obtida por
    meio de planilhas elaboradas pelo <b>CREDOR</b> e que demonstrem os lançamentos de débitos
    computados e a atualização do saldo devedor segundo os critérios convencionados. <b>13.1.</b>{' '}
    O(A) <b>EMITENTE</b> reconhece a forma de contratação por meios eletrônicos, digitais e
    informáticos como válida e plenamente eficaz, constituindo-se título executivo extrajudicial
    (art. 28, Lei nº 10.931/04) para todos os fins de direito, ainda que seja estabelecida com
    assinatura eletrônica ou certificação fora dos padrões ICP-BRASIL (art. 10, MP nº 2.200/2001).
    Esta <b>CCB</b> é digital e disponibilizada em vias idênticas.
    <b>14.</b> O <b>CREDOR</b> esclarece ao(à) <b>EMITENTE</b> que as informações sobre o montante
    dos débitos e das responsabilidades constituídos nesta <b>CCB</b> serão registradas no Sistema
    de Informações de Créditos (“<b>SCR”</b>), gerido pelo BCB, que tem por finalidade propiciar o
    intercâmbio entre as instituições obrigadas a prestar informações ao SCR acerca das operações
    referentes a débitos e responsabilidades (Res. n° 4.571/2017 e Circ. 3.870/2017 do BCB).{' '}
    <b>14.1 </b>O(A) <b>EMITENTE</b> poderá ter acesso aos dados do SCR por meio da Central de
    Atendimento BCB. As manifestações de discordância e os pedidos de correção, exclusão e registro
    de medidas judiciais deverão ser dirigidas ao SCR mediante requerimento escrito, acompanhado da
    respectiva decisão judicial quando for o caso. A consulta a qualquer informação constante no SCR
    dependerá de prévia autorização do(a) <b>EMITENTE</b>. <b>14.2.</b> O(A)
    <b>EMITENTE</b> declara-se ciente do esclarecimento feito na cláusula 14 e, neste ato, autoriza
    o <b>CREDOR</b> e os seus sucessores a consultarem e a registrarem os débitos e as
    responsabilidades decorrentes das operações de crédito que constem ou venham a constar em nome
    do(a) <b>EMITENTE</b>
    devedor no SCR ou nos sistemas que eventualmente substituam o SCR. <b>15.</b> Por se tratar de
    título de crédito (arts. 26, caput, e 29, §1º, Lei 10.931/2004), o <b>CREDOR</b> poderá
    endossar, ceder, vender, transferir ou entregar em penhor, total ou parcialmente, todos os
    direitos e garantias decorrentes desta <b>CCB</b>, sub-rogando-se o(s) cessionário(s) em todos
    os direitos, obrigações títulos de crédito e garantias decorrentes da presente <b>CCB</b>, e em
    todos os direitos, interesses, prerrogativas e garantias decorrentes do endosso, cessão, venda,
    transferência ou penhor, sem que haja necessidade de autorização prévia do(a) <b>EMITENTE</b>.{' '}
    <b>16.</b> O(A) <b>EMITENTE</b> declara-se ciente de que os dados cadastrais por ele(a)
    fornecidos para a realização do empréstimo servirão de base para confecção de seu cadastro.
    Ademais, autoriza expressamente o <b>CREDOR</b> a informar os dados relativos a todas as
    obrigações assumidas, nos termos desta <b>CCB</b>, para que constem nos cadastros compartilhados
    pelo <b>CREDOR</b> com outras instituições conveniadas, administradas por SERASA, SPC, CDL ou
    congêneres. <b>17.</b> O <b>CREDOR</b> tratará os dados pessoais (Lei 13.709/18 - “Lei Geral de
    Proteção de Dados” ou “LGPD”), recebidos neste ato e/ou coletados em razão desta <b>CCB</b>,
    somente para executar as obrigações contratuais aqui descritas, respeitados os limites e em
    razão das finalidades dispostas, e para atender obrigações legais e/ou regulatórias, conforme a
    LGPD. O <b>CREDOR</b> declara (i) adotar medidas técnicas e administrativas aptas a proteger os
    dados pessoais de tratamentos acidentais ou ilícitos; (ii) envidar os melhores esforços para
    manter os dados pessoais atualizados; (iii) compartilhar os dados pessoais com terceiros apenas
    quando necessário à execução desta <b>CCB</b>; e (iv) garantir aos dados pessoais, que sejam
    eventualmente transferidos internacionalmente, nível de proteção compatível com a LGPD.
    <b>17.1. </b>Os dados pessoais serão coletados, tratados e compartilhados pelo <b>CREDOR</b>,
    nos termos de sua Politica de Privacidade, disponível para consulta a qualquer tempo por meio do
    site https://www.bancointer.com.br/politica-de-privacidade/. Ainda, o <b>CREDOR</b> esclarecerá
    dúvidas relacionadas à privacidade pelo telefone 3003-4070 (capitais e regiões metropolitanas) e
    0800 940 0007 (para demais localidades) ou pelo e-mail privacidade@bancointer.com.br.{' '}
    <b>17.2.</b> O(A) <b>EMITENTE</b> reconhece que os tratamentos realizados no âmbito desta{' '}
    <b>CCB</b>
    restringem-se aos dados colhidos em razão deste instrumento e independem de tratamento realizado
    como decorrência de outra relação de natureza comercial, contratual ou pessoal existente entre o{' '}
    <b>CREDOR</b> e o(a) <b>EMITENTE</b> e de nenhuma forma limita, restringe, anula ou impede esse
    último tratamento. <b>17.3.</b> O(A) <b>EMITENTE</b> declara e garante que deseja receber
    publicidades, publicações e campanhas de marketing do <b>CREDOR</b>, reconhecendo que, a
    qualquer momento, poderá alterar suas preferências de marketing por meio da Central de
    Privacidade Inter ou pelos Canais de atendimento. <b>18.</b> Caso opte por contratar o seguro
    (item 2.17), o(a) <b>EMITENTE</b>
    concorda que a(s) parcela(s) respectiva(s) poderá(ão) ser financiada(s) e que o pagamento do
    montante relativo ao prêmio respectivo servirá para garantir a quitação do saldo devedor parcial
    ou total do contrato assumido junto ao estipulante e beneficiário do seguro.{' '}
    <b>
      18.1. Decorrido o prazo de 90 (noventa) dias do vencimento sem pagamento, o seguro (se
      contratado) ficará automaticamente e de pleno direito cancelado, independentemente de
      interpelação judicial ou extrajudicial, sem que caiba restituição de qualquer parcela do
      prêmio paga. No caso da ocorrência de sinistro durante o período de 90 (noventa) dias de
      atraso no pagamento do prêmio, a indenização, quando devida, será paga, nos termos das
      condições contratuais/regulamento do seguro, desde que os prêmios em atraso sejam previamente
      pagos, acrescidos de juros legais e atualização monetária. 18.2.
    </b>{' '}
    O(A) <b>EMITENTE</b> declara-se ciente de que, caso tenha interesse, poderá contratar o seguro
    com a seguradora de sua preferência, sob a condição de vinculá-lo a esta operação de crédito.
    Para tanto, deverá se informar acerca dos procedimentos e da documentação necessária junto à
    seguradora escolhida.
    <br />
    <b>19.</b> Ao assinar esta <b>CCB</b>, o(a) <b>EMITENTE</b> declara que: <b>a)</b> compreendeu
    integralmente o significado e conteúdo desta <b>CCB</b>; <b>b)</b> o mútuo se adequa plenamente
    às suas necessidades, seus interesses e seus objetivos; <b>c)</b> o <b>CREDOR</b> lhe prestou as
    informações necessárias para as suas livres escolha e tomada de decisão e explicitou os seus
    direitos, deveres, responsabilidades, custos ou ônus, penalidades e riscos existentes na
    execução das operações; <b>d)</b> as informações prestadas são verdadeiras e autênticas e que se
    compromete a informar ao <b>CREDOR</b> a respeito de quaisquer alterações em seus dados
    cadastrais; <b>e)</b> está ciente de que, nas contratações realizadas por dispositivos móveis de
    comunicação (mobile), caixas eletrônicos (ATM), internet ou outro meio permitido por lei (desde
    que fora do estabelecimento comercial), poderá desistir do contrato no prazo de até 07 (sete)
    dias, a contar do recebimento do crédito, devendo restituir o valor total concedido que lhe foi
    entregue, acrescido de eventuais tributos incidentes sobre a operação.
    <br />
    <br />
    Por estarem de pleno acordo quanto às condições acima avençadas, o(a) <b>EMITENTE</b> e o
    <b>CREDOR</b> confirmam as condições e as obrigações previstas nesta <b>CCB</b> lançada pelo(a){' '}
    <b>EMITENTE</b>, na data prevista (item 2.10), e em ( ) vias, sendo a via do <b>CREDOR</b> a
    única negociável, ao tempo em que ratificam a opção pelo foro de pagamento (item 2.15), eleito
    competente para a solução judicial de qualquer controvérsia decorrente desta <b>CCB</b>.{' '}
    <b>
      DECLARAÇÃO DE RECEBIMENTO DA <b>CCB</b>: O(A) <b>EMITENTE</b> declara ter recebido uma via
      original desta <b>CCB</b> nos exatos termos da Proposta de Crédito, da qual declara previa
      ciência e aceitação. Ademais, o(a) <b>EMITENTE</b> declara estar ciente e de acordo com as
      condições descritas nesta <b>CCB</b>. Declara ainda conhecer os canais de atendimento do{' '}
      <b>CREDOR</b> constantes nesta <b>CCB</b>, por meio dos quais poderá pedir cópia de
      documentos, saldo devedor ou outros serviços. O <b>CREDOR</b> não cobra e não autoriza que
      cobrem em seu nome nenhum valor pela intermediação e/ou liberação do empréstimo pleiteado
      pelo(a)
      <b>EMITENTE</b>. A proposta válida por 2 (dois) dias a partir da data de envio do documento.
      Em caso de dúvida, acesse o chat do Inter por meio do endereço eletrônico
    </b>{' '}
    https://ajuda.bancointer.com.br/pt-BR e clique na janela localizada no canto inferior direito
    para iniciar a conversa.
  </>
)
