import { Reducer } from 'redux'

import { DadosContaState, DadosContaTypes } from './types'

const INITIAL_STATE: DadosContaState = {
  telefone: '',
  email: '',
  conta: '',
  nome: '',
  cpf: '',
  dataNascimento: '',
  checkTelefone: false,
  segmentation: '',
}

const reducer: Reducer<DadosContaState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DadosContaTypes.SET_TELEFONE:
      return { ...state, telefone: action.payload }
    case DadosContaTypes.SET_EMAIL:
      return { ...state, email: action.payload }
    case DadosContaTypes.SET_CONTA:
      return { ...state, conta: action.payload }
    case DadosContaTypes.SET_NOME:
      return { ...state, nome: action.payload }
    case DadosContaTypes.SET_CPF:
      return { ...state, cpf: action.payload }
    case DadosContaTypes.SET_DATA_NASCIMENTO:
      return { ...state, dataNascimento: action.payload }
    case DadosContaTypes.SET_CHECK_TELEFONE:
      return { ...state, checkTelefone: action.payload }
    case DadosContaTypes.SET_SEGMENTATION:
      return { ...state, segmentation: action.payload }
    case DadosContaTypes.RESET:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}

export default reducer
