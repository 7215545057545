import * as React from 'react'

import { Container, Content, ContainerFooter } from './styles'

interface Props {
  children: React.ReactElement | React.ReactElement[]
  footer?: React.ReactElement | React.ReactElement[]
  marginTop?: string
}

const Page = ({ children, footer, marginTop }: Props) => (
  <>
    <Container>
      <Content>{children}</Content>
      <ContainerFooter marginTop={marginTop}>{footer}</ContainerFooter>
    </Container>
  </>
)

export default Page
