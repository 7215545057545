import React, { FormEvent, useCallback, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Colors } from '@utils/Colors'
import imagemOnboarding from '@images/onboarding_IR.png'
import { Paragraph, Separator } from '@atoms'
import { Button } from '@interco/inter-ui/components/Button'
import ChevronRight from '@interco/icons/orangeds/MD/chevron-right'
import TempoDeEntrega from '@interco/icons/orangeds/SM/tempo-de-entrega'
import Withdraw from '@interco/icons/orangeds/SM/withdraw'
import DepositByCheck from '@interco/icons/orangeds/SM/deposit-by-check'
import { SearchInput } from '@interco/inter-ui/components/SearchInput'
import ChevronDown from '@interco/icons/orangeds/SM/chevron-down'
import ChevronUp from '@interco/icons/orangeds/SM/chevron-up'
import { perguntasFrequentes, PERGUNTA_PADDING, PropostasStatus } from '@utils/constants'
import routes from '@routes/routes'
import GoogleTag from '@utils/GoogleTag'
import {
  callConsultaAntifraude,
  callGetExisteProposta,
} from '@store/impostoRenda/antecipacao/actions'
import { ApplicationState } from '@store/types'
import { setCurrentTab, setEnableTab } from '@store/ui/navigation/actions'

import {
  AccordionCustom,
  ButtonArea,
  CustomLabelAccordion,
  ImgOnboarding,
  InfoLine,
  InfoText,
  PFlexStart,
} from '../styles'
import { BottomSheetPropostas } from './Bottomsheets/BottomSheetPropostas'
import { PropostaVariant } from './Bottomsheets/variants'

const disableProd = false

const HomeSimulador = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [query, setQuery] = useState('')
  const [expanded, setExpanded] = useState('')
  const [filteredQuestions, setFilteredQuestions] = useState(perguntasFrequentes)
  const [showBottomSheet, setShowBottomSheet] = useState(false)
  const [variant, setVariant] = useState(PropostaVariant.propostaAndamento)
  const { cpf } = useSelector((state: ApplicationState) => state.impostoRenda.dadosConta)
  const { loading, existePropostaAndamento: hasProposta } = useSelector(
    (state: ApplicationState) => state.impostoRenda.antecipacao,
  )

  const handleInputChange = (e: FormEvent<HTMLInputElement>) =>
    setQuery((e.target as HTMLInputElement).value)

  const filterList = useCallback(() => {
    setFilteredQuestions(
      perguntasFrequentes.filter((pergItem) => pergItem.pergunta.includes(query)),
    )
  }, [query])

  useEffect(() => {
    if (query !== '') {
      filterList()
    } else {
      setFilteredQuestions(perguntasFrequentes)
    }
  }, [filterList, query])

  useEffect(() => {
    dispatch(
      callGetExisteProposta({
        callback: (existePropostaAndamento, status) => {
          if (existePropostaAndamento) {
            if (
              status === PropostasStatus.APROVADO ||
              status === PropostasStatus.PAGO ||
              status === PropostasStatus.LIQUIDADO
            ) {
              setVariant(PropostaVariant.propostaRealizada)
              setShowBottomSheet(true)
            } else if (
              status === PropostasStatus.EM_ANALISE ||
              status === PropostasStatus.AGUARDANDO_PAGAMENTO
            ) {
              setVariant(PropostaVariant.propostaAndamento)
              setShowBottomSheet(true)
            }
          }
        },
      }),
    )
  }, [dispatch])

  const callValidacao = () => {
    if (!hasProposta) {
      dispatch(
        callConsultaAntifraude({
          cpf: process.env.REACT_APP_MOCK_CPF_RESTITUICAO || cpf || '',
          callback: () => {
            navigate(routes.VALIDACAO)
          },
          invalidCallback: () => {
            navigate(routes.ANTECIPACAO_INDISPONIVEL)
          },
        }),
      )
    }
  }

  return (
    <>
      <ImgOnboarding
        src={imagemOnboarding}
        alt="Mulher sorrindo com celular na mão"
        loading="lazy"
      />
      <PFlexStart
        fontSize="24px"
        fontWeight="600"
        variant="sora"
        lineHeight="28px"
        color={Colors.GRAY500}
        textAlign="left"
        margin="20px 0 16px"
      >
        Antecipe sua restituição do Imposto de Renda
      </PFlexStart>
      <Paragraph
        fontSize="14px"
        fontWeight="normal"
        variant="inter"
        lineHeight="17px"
        color={Colors.GRAY400}
        textAlign="left"
        margin="0 0 30px"
      >
        Você tem valores a receber de volta depois de declarar seu Imposto de Renda? Aqui no Inter
        você pode recebê-los de uma vez, sem precisar esperar pelo prazo da Receita Federal.
      </Paragraph>
      <Button
        fullWidth
        icon={
          <ChevronRight
            height={24}
            width={24}
            color={disableProd || hasProposta ? Colors.GRAY300 : Colors.WHITE}
          />
        }
        isLoading={loading}
        disabled={disableProd || hasProposta}
        onClick={() => {
          GoogleTag.impostoRendaTag({
            ref_figma: 2,
            content_id: 'Simular antecipação',
            screen: 'Home',
            content_action: 'clique',
            area: 'Simulador',
          })
          callValidacao()
        }}
      >
        Simular a antecipação
      </Button>
      <Separator margin="32px -24px" />
      <Paragraph
        fontFamily="Sora"
        fontSize="20px"
        lineHeight="25px"
        color={Colors.GRAY500}
        fontWeight={600}
        margin="0 0 16px 0"
      >
        Vantagens de antecipar sua restituição no Inter
      </Paragraph>
      <InfoLine>
        <TempoDeEntrega width={24} height={24} />
        <InfoText>
          <Paragraph fontWeight={700} color={Colors.GRAY500}>
            Dinheiro na mão em até 4 dias úteis
          </Paragraph>
          <Paragraph>
            Ficou para o ultimo lote de pagamento da restituição? Aqui você antecipa o valor e
            recebe o dinheiro em até 4 dias.
          </Paragraph>
        </InfoText>
      </InfoLine>
      <InfoLine>
        <Withdraw width={24} height={24} />
        <InfoText>
          <Paragraph fontWeight={700} color={Colors.GRAY500}>
            Taxa competitiva no mercado
          </Paragraph>
          <Paragraph>Você antecipa a restituição do IR e paga só 2,80% a.m.</Paragraph>
        </InfoText>
      </InfoLine>
      <InfoLine>
        <DepositByCheck width={24} height={24} />
        <InfoText>
          <Paragraph fontWeight={700} color={Colors.GRAY500}>
            Antecipe até 100% da sua restituição
          </Paragraph>
          <Paragraph>Você faz tudo online pelo Super App.</Paragraph>
        </InfoText>
      </InfoLine>
      <Separator margin="32px -24px" />
      <Paragraph
        fontFamily="Sora"
        fontSize="20px"
        lineHeight="25px"
        color={Colors.GRAY500}
        fontWeight={600}
        margin="0 0 16px 0"
      >
        Perguntas frequentes
      </Paragraph>
      <SearchInput
        value={query}
        onChange={handleInputChange}
        isFullWidth
        placeholder="Pesquisar"
        style={{ marginBottom: '16px' }}
      />
      <>
        {filteredQuestions.map((perg) => (
          <AccordionCustom
            key={perg.pergunta}
            styleProp={perg.pergunta === PERGUNTA_PADDING}
            labelComponent={
              <CustomLabelAccordion>
                <Paragraph
                  color={Colors.GRAY500}
                  fontWeight={700}
                  margin="0 14px 0 0 "
                  textAlign="left"
                >
                  {perg.pergunta}
                </Paragraph>
                <ButtonArea>
                  {expanded === perg.pergunta ? (
                    <ChevronUp width={16} height={16} color={Colors.PRIMARY500} />
                  ) : (
                    <ChevronDown width={16} height={16} color={Colors.PRIMARY500} />
                  )}
                </ButtonArea>
              </CustomLabelAccordion>
            }
            expanded={expanded === perg.pergunta}
            onClick={() => {
              GoogleTag.impostoRendaTag({
                ref_figma: 7,
                flow: 'nome do fluxo',
                content_id: perg.pergunta,
                screen: 'Home',
                client: true,
                product: 'nome do produto',
              })
              if (expanded === perg.pergunta) {
                setExpanded('')
              } else {
                setExpanded(perg.pergunta)
              }
            }}
          >
            {perg.resposta}
          </AccordionCustom>
        ))}
      </>
      {showBottomSheet ? (
        <BottomSheetPropostas
          variant={variant}
          redirect={() => {
            if (variant === PropostaVariant.propostaAndamento) {
              dispatch(setEnableTab(true))
              setShowBottomSheet(false)
              dispatch(setCurrentTab('Area do Cliente'))
            } else {
              dispatch(setEnableTab(true))
              setShowBottomSheet(false)
              dispatch(setCurrentTab('Area do Cliente'))
            }
          }}
        />
      ) : (
        <></>
      )}
    </>
  )
}
export default HomeSimulador
